<template>
  <button @click="$emit('close')">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.7215 0.279075C23.3523 -0.0900947 22.7538 -0.0900956 22.3846 0.279074L12.0001 10.6637L1.61547 0.279074C1.2463 -0.0900956 0.647757 -0.0900947 0.278587 0.279075C-0.0905828 0.648245 -0.0905827 1.24679 0.278587 1.61596L10.6632 12.0005L0.278586 22.3851C-0.0905834 22.7543 -0.0905835 23.3528 0.278586 23.722C0.647756 24.0912 1.2463 24.0912 1.61547 23.722L12.0001 13.3374L22.3846 23.722C22.7538 24.0912 23.3524 24.0912 23.7215 23.722C24.0907 23.3528 24.0907 22.7543 23.7215 22.3851L13.3369 12.0005L23.7215 1.61596C24.0907 1.24679 24.0907 0.648245 23.7215 0.279075Z"
        fill="#1F2229"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "ButtonClose",
};
</script>
