<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <h2 class="title white">Общие правила использования POSM</h2>
      </div>
      <StickyComponent>
        <template #static>
          <div class="static">
            <div class="white">
              <div class="rule__white">
                <div class="rule-item">
                  <PointNumber :point="{ bg: '#53AEE2', number: 1 }">
                    <template #content>
                      <strong>Актуальность и стандарт POSm</strong>
                    </template>
                  </PointNumber>
                  <p class="container__text">
                    Все рекламные носители должны быть актуальными и не иметь
                    повреждений.
                  </p>
                </div>
                <div class="rule-item">
                  <PointNumber :point="{ bg: '#53AEE2', number: 2 }">
                    <template #content>
                      <strong>Размещение POSm в «горячих зонах»</strong>
                    </template>
                  </PointNumber>
                  <p class="container__text">
                    Рекламные материалы должны размещаться в заметных для
                    клиента местах. Размещение на&nbsp;уровне глаз (1,2-1,7м
                    от&nbsp;земли).
                  </p>
                </div>
                <div class="rule-item">
                  <PointNumber :point="{ bg: '#53AEE2', number: 3 }">
                    <template #content>
                      <strong
                        >Оформление точки продаж без «эффекта маскарада»</strong
                      >
                    </template>
                  </PointNumber>
                  <p class="container__text">
                    Запрещено дублирование рекламных материалов с одинаковой
                    коммуникацией.
                  </p>
                </div>
              </div>
            </div>
            <Attention
              text="Не допускать размещение испорченных POSm. Контролируйте и поддерживайте безупречное состояние рекламных материалов."
              class="desktop"
            />
          </div>
        </template>
        <template #scroll>
          <div class="rule__white grey">
            <img src="@/assets/img/rule-1.png" class="bs" />

            <p class="container__text">
              Рекламные материалы демонстрируют клиентам надежность оператора и
              несут идею компании, которой можно и нужно доверять. Рекламная
              продукция должна быть аккуратной, чистой и актуальной.
            </p>
          </div>
        </template>
      </StickyComponent>
    </div>
    <div class="container tablet">
      <Attention
        text="Не допускать размещение испорченных POSm. Контролируйте и поддерживайте безупречное состояние рекламных материалов."
      />
    </div>
  </div>
</template>

<script>
import Attention from "../Attention.vue";
import PointNumber from "../PointNumber.vue";
import StickyComponent from "../StickyComponent.vue";

export default {
  components: { StickyComponent, PointNumber, Attention },
  name: "CommonRules",
};
</script>

<style lang="scss" scoped>
.rule {
  .white {
    @include tablet {
      margin: 0 -15px;
      padding: 0 15px;
      background: white;
    }
  }
  h2.title {
    padding-top: 40px;
    margin-bottom: 32px;
    @include tablet {
      padding: 24px 15px 16px 15px;
      margin-bottom: 0;
    }
  }
  &-item {
    margin-bottom: 24px;
    .number {
      margin-bottom: 8px;
    }
  }
  .static {
    width: 540px;
    @include tablet {
      width: 100%;
      .white {
        padding-bottom: 24px;
      }
    }
  }
  .grey-block {
    padding-bottom: 72px;
    @include tablet {
      padding-bottom: 0;
    }
  }
  .grey {
    @include tablet {
      background: #f0f1f2;
      box-shadow: none;
      margin: 0 -15px;
      padding: 24px 15px 24px 15px;
    }
  }
  &__white {
    background: #ffffff;
    padding: 24px;
    margin-bottom: 32px;
    @include tablet {
      box-shadow: 0px 8px 16px -5px rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      margin-bottom: 0;
    }
    & .rule-item:last-child {
      margin-bottom: 0;
    }
    h3.title {
      font-family: var(--fontSerif);
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 24px;
      @include tablet {
        margin-bottom: 16px;
      }
    }
    .attention-icon {
      margin-bottom: 24px;
      @include tablet {
        margin-bottom: 16px;
      }
    }
    & p:nth-last-child(2) {
      margin-bottom: 24px;
      @include tablet {
        margin-bottom: 16px;
      }
    }
  }
  .bs {
    box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
  }
  img {
    margin-bottom: 24px;
    background: #ffffff;
    border-radius: 4px;
    @include tablet {
      margin-bottom: 16px;
    }
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: block;
    }
    .attention {
      margin: 24px 0;
    }
  }
}
</style>
