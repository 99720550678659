<template>
  <div
    class="absolute"
    :style="{ left: button.left + '%', top: button.top + '%' }"
  >
    <div class="button-survey" :class="button?.color || 'pink'">
      <div
        class="context"
        :class="[button.direction || 'to-right', { active: isShowContext }]"
      >
        {{ button.flag }}
      </div>
      <button
        type="button"
        @mouseenter="isShowContext = true"
        @mouseleave="isShowContext = false"
      >
        <span class="ripple"></span>
        <span class="ripple"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonSurvey",
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowContext: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes ripple {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
}
.absolute {
  position: absolute;
}
.button-survey {
  position: relative;
  width: fit-content;
  .context {
    position: absolute;
    font-size: 16px;
    line-height: 140%;
    padding: 4px 8px;
    border-radius: 4px;
    bottom: calc(100% + 5px);
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: all 0.3s;
    white-space: nowrap;
    pointer-events: none;
    z-index: 10;
    &.to-right {
      left: 0;
    }
    &.to-left {
      right: 0;
    }
    &.active {
      opacity: 1;
    }
  }
  button {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    cursor: auto;
    &::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;

      border: 3px solid #ffffff;
      border-radius: 50%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
      transition: all 0.3s;
    }
    &:hover::after {
      width: 22px;
      height: 22px;
    }
    &:hover {
      box-shadow: none;
    }
    .ripple {
      position: absolute;
      width: 50px;
      height: 50px;
      opacity: 0;
      border: 3px solid #ffffff;
      border-radius: 50%;
      animation: ripple 2000ms infinite;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      pointer-events: none;
    }
    & .ripple:nth-child(1) {
      animation-delay: 0.3s;
    }

    & .ripple:nth-child(2) {
      animation-delay: 0.6s;
    }
  }
  &.pink {
    button {
      &::before {
        background: #f09dc2;
      }
      &::after {
        background: #ff59a3;
      }
    }
  }
  &.blue {
    button {
      &::before {
        background: #a1deff;
      }
      &::after {
        background: #53aee2;
      }
    }
  }
  &.green {
    button {
      &::before {
        background: #e5fd8a;
      }
      &::after {
        background: #c7e553;
      }
    }
  }
  &.orange {
    button {
      &::before {
        background: #ffc6b0;
      }
      &::after {
        background: #ec6430;
      }
    }
  }
}
</style>
