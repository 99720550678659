<template>
  <div class="flip-card">
    <div class="front">
      <div class="front__title">
        <p v-html="card.title" />
      </div>
      <div class="front__image">
        <img :src="require(`@/assets/img/${card.img}`)" />
      </div>
    </div>
    <router-link :to="card.go_to">
      <div class="back">
        <div class="back__content">
          <p>{{ card.back_text }}</p>
          <router-link :to="card.go_to" class="btn btn-primary">
            Подробнее
          </router-link>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FlipCard",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.flip-card {
  width: 252px;
  height: 252px;
  border-radius: 8px;
  perspective: 1000px;
  position: relative;
  @include onlyMobile {
    width: 100%;
    height: 100%;
    max-height: 252px;
    max-width: 252px;
  }
  & > * {
    pointer-events: none;
    @include tablet {
      pointer-events: all;
    }
  }
}
.front {
  width: 252px;
  height: 252px;
  border-radius: 8px;
  background: #34383f;
  overflow: hidden;
  box-shadow: 0px 12px 20px -4px rgba(0, 0, 0, 0.18);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  position: absolute;
  @include onlyMobile {
    width: 100%;
    height: 100%;
    max-height: 252px;
    max-width: 252px;
  }
  &__image {
    background: #34383f;
    @include onlyMobile {
      height: 136px;
    }
  }
  &__title {
    color: #ffffff;
    max-width: 204px;
    top: 24px;
    left: 24px;
    position: absolute;
    @include onlyMobile {
      max-width: 110px;
      top: 13px;
      left: 13px;
    }
    p {
      font-size: 20px;
      line-height: 140%;
      @include onlyMobile {
        font-size: 12px;
        line-height: 140%;
      }
    }
  }
  .flip-card:hover & {
    transform: rotateY(180deg);
  }
  .flip-card:nth-child(even):hover & {
    transform: rotateY(-180deg);
  }
}
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8e8ed;
  box-shadow: 0px 12px 20px -4px rgba(0, 0, 0, 0.18);
  width: 252px;
  height: 252px;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  transform: rotateY(-180deg);
  @include onlyMobile {
    width: calc(100% - 20px);
    height: 100%;
    max-height: 252px;
    max-width: 252px;
    padding: 0 10px;
  }
  .flip-card:hover & {
    transform: rotateY(0deg);
  }
  .flip-card:nth-child(even) & {
    transform: rotateY(180deg);
  }
  .flip-card:nth-child(even):hover & {
    transform: rotateY(0deg);
  }
  &__content {
    width: 212px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include onlyMobile {
      height: 100%;
      justify-content: center;
    }
    p {
      font-size: 16px;
      line-height: 140%;
      max-width: 212px;
      text-align: center;
      @include onlyMobile {
        margin-bottom: 10px;
        max-width: 100%;
        font-size: 10px;
        line-height: 140%;
      }
    }
    & > * {
      pointer-events: all;
    }
  }
}
</style>
