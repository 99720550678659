<template>
  <Content>
    <template #title> ИНСтрукции по оформлению рекламных кампаний </template>
    <template #main>
      <div class="instr">
        <div class="container">
          <div class="grey-block instr-grid">
            <div>
              <h2 class="title mb24">Региональные инструкции</h2>
              <div class="container__text" v-html="doc_types.regional" />
            </div>

            <div>
              <Card
                v-if="regionalSection"
                :config="{
                  style: { background: '#34383F', color: 'white' },
                  title: regionalSection.name,
                  img: mediaUrl + '/' + regionalSection.image,
                }"
                @openModal="changeShowModal(regionalSection)"
              />
            </div>
          </div>
          <div class="instr-attention">
            <p>
              Для поддержания актуальности рекламных коммуникаций используй
              <!-- <router-link to="/" class="link link--pink"
                >Руководство</router-link
              > -->
              Руководство и акутальную
              <!-- <router-link to="/" class="link link--blue"
                >Инструкцию по оформлению</router-link
              > -->
              Инструкцию по оформлению.<br /><br />
              В случае противоречия требований “Руководства” и “Инструкций”
              следуй требованиям “Инструкций”, так как данный документ
              обновляется чаще и содержит более оперативную информацию.
            </p>
          </div>
          <h2 class="title mb24">Федеральные инструкции</h2>
          <div class="container__text" v-html="doc_types.federal" />
          <div v-if="federalSections.length" class="instr-grid-cards">
            <Card
              v-for="(item, i) of federalSections"
              :key="i"
              :config="{
                style: { background: '#E8E8ED', color: '#1F2229' },
                title: item.name,
                img: mediaUrl + '/' + item.image,
              }"
              @openModal="changeShowModal(item)"
            />
          </div>
        </div>
        <div v-if="loading" class="page-loader">
          <Loader :model-value="loading" color="#70c9f8" :size="70" />
        </div>
        <InstructionsModal
          v-if="isShow"
          :modal="modal"
          @close="changeShowModal"
        />
      </div>
    </template>
  </Content>
</template>

<script>
import { getRegions, getDocTypes, getDocSections } from "@/api";
import Content from "@/components/Content.vue";
import Card from "@/components/Card.vue";
import InstructionsModal from "@/components/InstructionsModal.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "Instructions",
  components: {
    Content,
    Card,
    InstructionsModal,
    Loader,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      modal: {},
      doc_types: {
        regional: "",
        federal: "",
      },
      regionalSection: null,
      federalSections: [],
      regions: [],
      fetchRegions: getRegions,
      docTypes: getDocTypes,
      docSections: getDocSections,
    };
  },
  async created() {
    this.loading = true;
    this.regions = await this.fetchRegions();
    const docTypes = await this.docTypes();
    this.doc_types = {
      regional: docTypes.find((el) => el.type === "regional").description,
      federal: docTypes.find((el) => el.type === "federal").description,
    };
    const docSectionsRegional = await this.docSections({
      type: "regional",
      sort: "id",
    });
    this.regionalSection = docSectionsRegional[0];
    this.regionalSection.type = "regional";
    this.federalSections = await this.docSections({
      type: "federal",
      sort: "id",
    });
    this.loading = false;
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_SITE_MEDIA;
    },
  },
  methods: {
    changeShowModal(params) {
      if (!this.isShow) {
        this.modal = {
          title: params.name,
          section_id: params.id,
          type_id: params.type_id,
          type: params.type,
        };
      }
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style lang="scss" scoped>
.instr {
  padding-bottom: 64px;
  @include onlyMobile {
    padding-bottom: 36px;
  }
  .mb24 {
    @include onlyMobile {
      margin-bottom: 18px;
    }
  }
  &-grid {
    padding: 40px 0;
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(290px, 636px) 252px;
    grid-gap: 36px;
    margin-bottom: 40px;
    margin-top: 36px;
    align-items: center;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 18px;
      margin: 36px -15px 40px -15px;
      padding: 40px 15px;
    }
    @include onlyMobile {
      padding: 18px 15px 36px 15px;
      margin: 24px -15px;
    }
  }
  &-attention {
    padding: 15px 19px;
    background: #ffffff;
    box-shadow: 0px 3px 16px rgba(0, 181, 239, 0.2);
    border-radius: 2px;
    margin-bottom: 64px;
    @include onlyMobile {
      margin-bottom: 36px;
    }
    p {
      font-size: 14px;
    }
  }
  &-grid-cards {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(4, 252px);
    grid-gap: 36px;
    @include tablet {
      margin-top: 18px;
      justify-content: center;
      grid-template-columns: repeat(2, 252px);
      grid-gap: 18px;
    }
    @include onlyMobile {
      grid-template-columns: repeat(2, 136px);
    }
  }
}
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: grid;
  place-content: center;
  background: white;
}
</style>
