<template>
  <Content>
    <template #title> требования к фотоотчетам торговых точек </template>
    <template #main>
      <div class="reports">
        <div class="container">
          <div class="grid">
            <div class="rules">
              <h2 class="title desktop">Обязательные требования:</h2>
              <AttentionIcon
                :icon="{ name: 'alert.svg', w: 20, h: 20 }"
                text="Каждая фотография должна быть без размытий, с полноценным «фокусом» камеры"
              />
              <AttentionIcon
                :icon="{ name: 'alert.svg', w: 20, h: 20 }"
                text="Каждая фотография должна быть ОБЗОРНОЙ, т. е. предоставлять обзор о том, ГДЕ размещаются POSM"
              />
              <AttentionIcon
                :icon="{ name: 'alert.svg', w: 20, h: 20 }"
                text="Минимальное количество фотографий, прикрепленных к отчету о посещении — минимум 4 обзорных фотографии с разных ракурсов"
              />
              <h2 class="title title-2">Содержание фотоотчета:</h2>
              <div class="white-block">
                <PointNumber
                  :point="{ number: 1, bg: '#53AEE2' }"
                  class="new-line"
                >
                  <template #content>
                    <div class="container__text">
                      Общий вид торговой точки:<br />
                      <ul class="links">
                        <li>
                          <button
                            @click="slideTo(1)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Фото фасада или фото входа в торговую точку с
                            вывеской партнера
                          </button>
                        </li>
                        <li>
                          <button
                            @click="slideTo(2)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Входная группа с размещенными материалами Tele2 на
                            ней <i>(при&nbsp;наличии)</i>
                          </button>
                        </li>
                        <li>
                          <button
                            @click="slideTo(3)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Фото всех наружных рекламных поверхностей Tele2
                            <i>(при&nbsp;наличии)</i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </template>
                </PointNumber>
                <PointNumber
                  :point="{ number: 2, bg: '#53AEE2' }"
                  class="new-line"
                >
                  <template #content>
                    <div class="container__text">
                      Фото внутри торговой точки:<br />
                      <ul class="links">
                        <li>
                          <button
                            @click="slideTo(4)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Фото торгового зала
                          </button>
                        </li>
                        <li>
                          <button
                            @click="slideTo(5)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Фото витрин с размещенным товаром и POSM операторов
                            связи
                          </button>
                        </li>
                        <li>
                          <button
                            @click="slideTo(6)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Фото кассовой зоны
                          </button>
                        </li>
                        <li>
                          <button
                            @click="slideTo(7)"
                            :class="
                              device === 'desktop' ? 'link link--blue' : ''
                            "
                          >
                            Фото размещенных POSM и всех обязательных материалов
                          </button>
                        </li>
                      </ul>
                    </div>
                  </template>
                </PointNumber>
                <PointNumber
                  :point="{ number: 3, bg: '#53AEE2' }"
                  class="new-line"
                >
                  <template #content>
                    <p class="container__text">
                      Если в торговой точке есть любые отклонения от стандартов
                      оформления, инициированные партнером, а также нарушения
                      паритетов представленности со стороны конкурентов — <br />
                      <strong
                        >ОБЯЗАТЕЛЬНО ФОТОГРАФИРУЙТЕ ЭТИ ОТКЛОНЕНИЯ.</strong
                      >
                    </p>
                  </template>
                </PointNumber>
              </div>
            </div>
            <div class="grey-block photo desktop">
              <h2 class="title">Примеры обзорных фото:</h2>
              <div class="grid-swiper">
                <ButtonLeft />
                <Swiper
                  @swiper="onSwiper"
                  :pagination="{
                    el: '.swiper-pagination',
                  }"
                  :navigation="{
                    nextEl: '.button-right',
                    prevEl: '.button-left',
                  }"
                  :autoHeight="true"
                  :spaceBetween="43"
                  :slidesPerView="1"
                  :loop="true"
                >
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-2.png" />
                      <div class="item-title">
                        <strong
                          >Фото фасада в торговую точку с вывеской
                          партнера</strong
                        >
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-6.jpeg" />
                      <div class="item-title">
                        <strong
                          >Входная группа с размещенными материалами Tele2 на
                          ней</strong
                        >
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-10.jpeg" />
                      <div class="item-title">
                        <strong
                          >Фото всех наружных рекламных поверхностей
                          Tele2</strong
                        >
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-8.jpg" />
                      <div class="item-title">
                        <strong>Фото торгового зала</strong>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-5.jpeg" />
                      <div class="item-title">
                        <strong
                          >Фото витрин с размещенным товаром и POSM операторами
                          связи</strong
                        >
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-7.jpg" />
                      <div class="item-title">
                        <strong>Фото кассовой зоны</strong>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="report-9.jpeg" />
                      <div class="item-title">
                        <strong
                          >Фото размещенных POSM и всех обязательных
                          материалов</strong
                        >
                      </div>
                    </div>
                  </SwiperSlide>
                  <div class="swiper-pagination" />
                </Swiper>
                <ButtonRight />
              </div>
            </div>
          </div>
        </div>
        <div class="grey-block photo tablet">
          <div class="container">
            <h2 class="title">Примеры обзорных фото:</h2>
            <div class="grid-swiper">
              <Swiper
                :pagination="{
                  el: '.swiper-pagination',
                }"
                :autoHeight="true"
                :spaceBetween="43"
                :slidesPerView="1"
                :loop="true"
              >
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-2.png" />
                    <div class="item-title">
                      <strong
                        >Фото фасада в торговую точку с вывеской
                        партнера</strong
                      >
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-6.jpeg" />
                    <div class="item-title">
                      <strong
                        >Входная группа с размещенными материалами Tele2 на ней
                      </strong>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-10.jpeg" />
                    <div class="item-title">
                      <strong
                        >Фото всех наружных рекламных поверхностей Tele2</strong
                      >
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-8.jpg" />
                    <div class="item-title">
                      <strong>Фото торгового зала</strong>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-5.jpeg" />
                    <div class="item-title">
                      <strong
                        >Фото витрин с размещенным товаром и POSM операторами
                        связи</strong
                      >
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-7.jpg" />
                    <div class="item-title">
                      <strong>Фото кассовой зоны</strong>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="item">
                    <WhiteBlock img="report-9.jpeg" />
                    <div class="item-title">
                      <strong
                        >Фото размещенных POSM и всех обязательных
                        материалов</strong
                      >
                    </div>
                  </div>
                </SwiperSlide>
                <div class="swiper-pagination" />
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import Content from "@/components/Content.vue";
import AttentionIcon from "@/components/AttentionIcon.vue";
import PointNumber from "@/components/PointNumber.vue";
import ButtonLeft from "@/components/ButtonLeft.vue";
import ButtonRight from "@/components/ButtonRight.vue";
import WhiteBlock from "@/components/WhiteBlock.vue";
SwiperCore.use([Pagination, Navigation]);

export default {
  components: {
    Content,
    AttentionIcon,
    PointNumber,
    ButtonLeft,
    ButtonRight,
    WhiteBlock,
    Swiper,
    SwiperSlide,
  },
  name: "PhotoReports",
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    slideTo(num) {
      this.swiper.slideTo(num);
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  margin-top: 40px;
  padding-bottom: 62px;
  @include tablet {
    padding-bottom: 0;
  }
  @include onlyMobile {
    margin-top: 0;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 36px;
    align-items: flex-start;
    @include tablet {
      grid-template-columns: 540px;
      justify-content: center;
    }
    @include onlyMobile {
      grid-template-columns: 1fr;
    }
    &-swiper {
      display: grid;
      grid-template-columns: 40px 1fr 40px;
      grid-gap: 10px;
      align-items: center;
      @include tablet {
        grid-template-columns: 1fr;
      }
    }
  }
  .rules {
    @include tablet {
      margin-bottom: 42px;
    }

    .title-2 {
      margin-bottom: 16px !important;
      margin-top: 32px;
    }
    .title {
      margin-bottom: 32px;
      @include onlyMobile {
        margin-bottom: 18px;
      }
    }
    & .attention-icon:nth-child(2) {
      margin-bottom: 24px;
      @include onlyMobile {
        margin-bottom: 18px;
      }
    }
    & .attention-icon:nth-child(3) {
      margin-bottom: 32px;
      @include onlyMobile {
        margin-bottom: 42px;
      }
    }
    .white-block {
      padding: 24px 23px;
      @include onlyMobile {
        padding: 16px 17px 24px 16px;
      }
      .number {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        &.new-line {
          @include onlyMobile {
            grid-template-columns: 1fr;
          }
        }
      }
    }
    .links {
      padding-left: 22px;
      li {
        margin-bottom: 0;
        &::after {
          content: "";
          background: var(--mainColor);
          width: 3px;
          height: 3px;
          border-radius: 50%;
          position: absolute;
          left: -13px;
          top: 8px;
        }
        button {
          text-align: left;
        }
      }
    }
  }
  .photo {
    padding: 24px 24px 34px 24px;
    @include onlyMobile {
      padding: 18px 0 18px 0;
    }
    .swiper-container {
      // padding: 0 20px 34px 20px;
      padding-bottom: 34px;
      @include onlyMobile {
        padding: 0 15px 28px 15px;
        margin: 0 -15px;
      }
      .swiper-pagination {
        bottom: -24px !important;
        @include onlyMobile {
          bottom: -18px !important;
        }
      }
    }
    .title {
      text-align: center;
      margin-bottom: 42px;
      @include onlyMobile {
        margin-bottom: 18px;
      }
    }
    .item {
      width: 348px;
      margin: 0 auto;
      @include onlyMobile {
        width: 290px;
      }
      &-title {
        font-size: 16px;
        text-align: center;
        margin-top: 24px;
        @include onlyMobile {
          text-align: left;
          font-size: 14px;
          margin-top: 18px;
        }
      }
    }
    .button-left {
      // position: absolute;
      // left: 24px;
      // top: 148px;
      margin-bottom: 70px;
      height: 40px;
    }
    .button-right {
      // position: absolute;
      // right: 24px;
      // top: 148px;
      margin-bottom: 70px;
      height: 40px;
    }
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
</style>
