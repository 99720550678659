<template>
  <div class="white-block" :class="fullImage ? 'full' : 'auto'">
    <img :src="require(`@/assets/img/${img}`)" />
  </div>
</template>

<script>
export default {
  name: "WhiteBlock",
  props: {
    img: {
      type: String,
      required: true,
    },
    fullImage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.white-block {
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
  border-radius: 4px;
  width: 338px;
  height: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.full {
    padding: 0px;
  }
  &.auto {
    padding: 5px;
  }
  img {
    max-height: 100%;
    width: auto;
    max-width: 348px;
    @include onlyMobile {
      max-width: 280px;
    }
  }
  @include onlyMobile {
    width: 280px;
    height: 280px;
  }
}
</style>
