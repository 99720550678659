<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <RulesContent>
          <template #top>
            <h2 class="title">Примеры оформления витрины</h2>
            <SurveyNumbers :config="config">
              <template #title>
                <h3 class="title">
                  Зона витрин <span>ОБЯЗАТЕЛЬНО</span> должна быть оформлена как
                  минимум одним POSM из ниже перечисленных:
                </h3>
              </template>
              <template #info>
                <p class="container__text link-m">
                  <strong>Дополнительно могут быть использованы:</strong>
                </p>
                <ul>
                  <li>
                    <strong
                      class="dop"
                      @mouseenter="config.imgActive = 1"
                      @mouseleave="config.imgActive = 0"
                      >Топпер</strong
                    >
                    — предназначен для увеличения представленности Tele2,
                    обозначения зоны выкладки, обеспечения приоритета оформления
                    над конкурентами. Размещается строго над бренд-блоком.
                  </li>
                  <li>
                    <strong
                      class="dop"
                      @mouseenter="config.imgActive = 2"
                      @mouseleave="config.imgActive = 0"
                      >Шелфтокер со вставкой</strong
                    >
                    — предназначен для анонсирования ключевых предложений Tele2.
                    Допускается размещение во всех витринах на уровне глаз
                    клиента, без привязки к брендблоку.
                  </li>
                  <li>
                    <strong
                      class="dop"
                      @mouseenter="config.imgActive = 3"
                      @mouseleave="config.imgActive = 0"
                      >Вобблер</strong
                    >
                    — предназначен для создания акцента на ключевых предложениях
                    о продуктах Tele2.
                  </li>
                </ul>
                <p class="container__text link-m">
                  Расширенный список POSm для оформления точки продаж можно
                  посмотреть в Каталоге POSm
                </p>
              </template>
            </SurveyNumbers>
          </template>
          <template #bottom>
            <h2 class="title">Правила оформления витрины</h2>
            <p class="container__text--big link-m">
              <strong
                >В витринах необходимо подсвечивать возможность подключения к
                Tele2, при выборе покупки клиент уделяет особое внимание товару
                в витринах. Сильный эффект оказывает кросс-выкладка. Например,
                размещение рядом со смартфоном тейблтента с тарифом для
                смартфона.</strong
              >
            </p>
            <div class="white-content">
              <div class="grid align-center">
                <div class="grey">
                  <BeforeAfter
                    before="before-4.png"
                    after="after-4.png"
                    offset="0.8"
                  />
                </div>
                <div>
                  <p class="container__text">
                    <strong
                      >Оформляя витрины и размещая POSm, следуйте
                      правилам:</strong
                    >
                  </p>
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'eye.svg', w: 25, h: 19 }"
                    text="Все POSm должны размещаться в «горячих зонах», на уровне глаз: ~1,6-1,8 м от пола."
                  />
                  <AttentionIcon :icon="{ name: 'sheets.svg', w: 23, h: 24 }">
                    <template #text>
                      <p class="container__text">
                        POSm должны быть достаточно заметными, нельзя допускать
                        <router-link to="/rules?rule=1" class="link link--blue"
                          >«эффект маскарада»</router-link
                        >
                      </p>
                    </template>
                  </AttentionIcon>
                </div>
              </div>
            </div>
          </template>
        </RulesContent>
        <h1 class="title">Зонирование торговых точек</h1>
        <ButtonLink :button="{ name: 'Витрины', path: '/zones?zone=green' }" />
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIcon from "../AttentionIcon.vue";
import BeforeAfter from "../BeforeAfter.vue";
import ButtonLink from "../ButtonLink.vue";
import RulesContent from "../RulesContent.vue";
import SurveyNumbers from "../SurveyNumbers.vue";

export default {
  components: {
    RulesContent,
    AttentionIcon,
    ButtonLink,
    SurveyNumbers,
    BeforeAfter,
  },
  name: "ShowcaseDesign",
  data() {
    return {
      config: {
        img: [
          "rule-5.png",
          "rule-5-topper.png",
          "rule-5-shelftocker.png",
          "rule-5-wobler.png",
        ],
        imgActive: 0,
        buttons: [
          { bg: "#EC6430", number: 1, left: 45, top: 15, flag: "Бренд-блок" },
          {
            bg: "#FF59A3",
            number: 2,
            left: 85,
            top: 42,
            flag: "Стоппер",
            direction: "to-left",
          },
        ],
        points: [
          {
            bg: "#EC6430",
            number: 1,
            link: true,
            text: "Бренд-блок",
            desc: "ББ - размещается только в витринах или в кассовой зоне. В остальных зонах размещать запрещено.<br>Размер бренд-блока, размещаемого в торговой точке, определяется размерами витрины (или&nbsp;кассовой зоной), либо утверждается магазином. При&nbsp;размещении бренд-блока в витрине, для дополнительной визуализации рекомендуется размещать шелфтокер под бренд-блоком.<br><strong>Картонный муляж/Тейбл-тент А5</strong><br>допускается размещение на холодильниках, подоконниках",
          },
          {
            bg: "#FF59A3",
            number: 2,
            link: true,
            text: "Стоппер",
            desc: "материал предназначен для увеличения представленности Tele2, анонсирования и привлечения внимания к самым выгодным предложениям.<br>Вплотную к стене не размещается<br>Размещение на уровне глаз",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.rule {
  .grey-block {
    padding-bottom: 64px;
    @include tablet {
      padding-bottom: 24px;
    }
  }
  h2.title {
    margin-bottom: 32px;
    @include tablet {
      display: none;
    }
  }
  .white-content {
    padding: 24px;
    background: #ffffff;
    margin-top: 32px;
    img.bs {
      box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
      border-radius: 4px;
    }
    @include tablet {
      margin-top: 24px;
      padding: 0;
    }
  }
  .dop {
    transition: all 0.3s;
    &:hover {
      color: #a1c611;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding-bottom: 24px;
    }
    &.align-center {
      align-items: center;
    }
    .m24 {
      margin: 24px 0;
      @include tablet {
        margin: 16px 0;
      }
    }
  }
  h3.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 16px;
    @include tablet {
      font-size: 18px;
      line-height: 140%;
    }
    span {
      color: #ff59a3;
    }
  }
  .link-m.container__text {
    margin: 32px 0 8px 0;
    @include tablet {
      margin: 24px 0 8px 0;
    }
    a {
      font-size: 16px;
    }
  }
  h1.title {
    font-family: var(--fontStencil);
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 56px;
    margin-bottom: 16px;
    @include tablet {
      margin-top: 24px;
      font-size: 20px;
      line-height: 120%;
    }
  }
  .button-link {
    max-width: 252px;
    @include onlyMobile {
      max-width: 100%;
    }
  }
  .grey {
    @include tablet {
      margin: 0 -15px;
      padding: 18px 15px 18px 15px;
      background: #f0f1f2;
    }
  }
  &-white {
    padding: 18px 20px 14px 16px;
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
</style>
