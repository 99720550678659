<template>
  <div :class="['btn-multi', { slide: buttons[1].includes(selected) }]">
    <button
      v-for="(btn, i) of buttons"
      :key="i"
      :class="[
        'btn-multi-item',
        {
          active:
            selected == btn || selected == btn.name || btn.includes(selected),
        },
      ]"
      type="button"
      @click="$emit('select', btn)"
    >
      {{ btn.name ? btn.name : btn }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonMulti",
  props: {
    buttons: {
      type: Array,
      require: true,
      default: () => [],
    },
    selected: {
      type: String,
      require: true,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-multi {
  border: 2px solid #1f2229;
  box-sizing: border-box;
  border-radius: 5px;
  width: fit-content;
  padding: 2px;
  position: relative;
  z-index: 10;
  @include onlyMobile {
    display: flex;
    flex-wrap: nowrap;
  }
  &::before {
    content: "";
    position: absolute;
    background: var(--mainColor);
    width: 139px;
    height: 26px;
    border-radius: 5px;
    left: 2px;
    transition: all 0.3s;
    z-index: -1;
  }
  &.slide {
    &::before {
      transform: translateX(100%);
    }
  }
  &-item {
    border: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    font-family: "Tele2 DisplaySerif";
    padding: 5px 20px;
    border-radius: 5px;
    font-style: normal;
    cursor: pointer;
    width: 139px;
    transition: all 0.3s;
    z-index: 1;
    &:hover {
      color: #797a7f;
    }
    &.active {
      color: #ffffff;
    }
  }
}
</style>
