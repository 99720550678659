<template>
  <div class="attention">
    <h5 class="title">Важно:</h5>
    <p class="container__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "Attention",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.attention {
  padding: 18px 15px;
  background: #ffffff;
  box-shadow: 0px 3px 16px -5px rgba(255, 89, 163, 0.2);
  border-radius: 2px;

  h5.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    color: #ff59a3;
    margin-bottom: 18px;
  }
}
</style>
