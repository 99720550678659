<template>
  <div class="white">
    <ButtonMulti
      v-if="device !== 'desktop'"
      :buttons="['Примеры', 'Правила']"
      :selected="selected"
      @select="selected = $event"
    />
    <div
      v-if="
        (selected == 'Примеры' && device !== 'desktop') || device == 'desktop'
      "
      class="top"
    >
      <slot name="top" />
    </div>
    <div
      v-if="
        (selected == 'Правила' && device !== 'desktop') || device == 'desktop'
      "
      class="bottom"
    >
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
import ButtonMulti from "./ButtonMulti.vue";

export default {
  components: { ButtonMulti },
  name: "RulesContent",
  props: {},
  data() {
    return {
      selected: "Примеры",
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-multi {
  @include tablet {
    margin-bottom: 24px;
  }
}
.top {
  padding-top: 40px;
  @include tablet {
    padding-top: 0;
  }
}
.bottom {
  padding-top: 64px;
  @include tablet {
    padding-top: 0;
  }
}
</style>
