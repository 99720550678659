<template>
  <Content>
    <template #title> Правила размещения POSm </template>
    <template #main>
      <div class="rules">
        <div class="grey-block">
          <div class="container">
            <Tabs @tab="rule = $event" />
          </div>
        </div>
        <CommonRules v-if="rule === 1" />
        <RequiredPOSM v-if="rule === 2" />
        <ExternalDesign v-else-if="rule === 3" />
        <InternalDesign v-else-if="rule === 4" />
        <ShoppingRoomDesign v-else-if="rule === 5" />
        <ShowcaseDesign v-else-if="rule === 6" />
        <BrandBlock v-else-if="rule === 7" />
        <CheckoutAreaDesign v-else-if="rule === 8" />
      </div>
    </template>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import Tabs from "@/components/Tabs.vue";
import BrandBlock from "@/components/rules/BrandBlock.vue";
import CheckoutAreaDesign from "@/components/rules/CheckoutAreaDesign.vue";
import ShowcaseDesign from "@/components/rules/ShowcaseDesign.vue";
import ShoppingRoomDesign from "@/components/rules/ShoppingRoomDesign.vue";
import InternalDesign from "@/components/rules/InternalDesign.vue";
import ExternalDesign from "@/components/rules/ExternalDesign.vue";
import CommonRules from "@/components/rules/CommonRules.vue";
import RequiredPOSM from "@/components/rules/RequiredPOSM.vue";

export default {
  components: {
    Content,
    Tabs,
    BrandBlock,
    CheckoutAreaDesign,
    ShowcaseDesign,
    ShoppingRoomDesign,
    InternalDesign,
    ExternalDesign,
    CommonRules,
    RequiredPOSM,
  },
  name: "Rules",
  data() {
    return {
      rule: 1,
    };
  },
  watch: {
    "$route.query": function (val) {
      this.rule = +val.rule || 1;
    },
  },
  mounted() {
    let rule = +this.$route.query.rule;
    this.rule = rule || 1;
    if (rule < 1 || rule > 7 || isNaN(rule)) this.rule = 1;
  },
};
</script>

<style lang="scss" scoped>
.rules {
  .grey-block {
    margin-top: 36px;
    @include tablet {
      margin-top: 24px;
    }
  }
}
</style>
