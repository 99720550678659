<template>
  <router-link class="button-link" :to="button.path">
    <span>{{ button.name }}</span>
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.950058 8.02567H19.7339L13.5458 1.66314C13.3738 1.4844 13.2686 1.24159 13.2686 0.974052C13.2686 0.706511 13.3744 0.4637 13.5464 0.284403C13.715 0.10904 13.9527 0 14.2164 0C14.48 0 14.7177 0.10904 14.8863 0.284403L22.7227 8.34323C22.8942 8.52197 23 8.76478 23 9.03176C23 9.29874 22.8942 9.54154 22.7222 9.72084L14.9491 17.7139C14.7805 17.8904 14.5428 18 14.2792 18C14.0155 18 13.7778 17.8904 13.6092 17.7139C13.4372 17.5346 13.3314 17.2918 13.3314 17.0248C13.3314 16.7578 13.4372 16.515 13.6092 16.3357L19.7967 9.97265H0.950624C0.422123 9.95578 0 9.52637 0 8.9986C0 8.47082 0.422123 8.0414 0.948927 8.02454L0.950058 8.02567Z"
        fill="#1F2229"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  name: "ButtonLink",
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 2px solid #1f2229;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px;
  position: relative;
  &::before {
    content: "";
    height: 100%;
    width: 0;
    background: var(--mainColor);
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s;
    z-index: 0;
  }
  &:hover::before {
    width: 100%;
  }
  &:hover span {
    color: #ffffff;
  }
  &:hover svg path {
    fill: #ffffff;
  }
  &:active::before {
    background: #e5e5e5;
    border-radius: 6px;
  }
  &:active {
    background: #e5e5e5;
  }
  &:active svg path,
  span {
    fill: var(--mainColor);
  }
  &:active span {
    color: var(--mainColor);
  }
  svg path {
    transition: all 0.3s;
  }
  svg {
    z-index: 1;
  }
  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    transition: all 0.3s;
    z-index: 1;
  }
}
</style>
