<template>
  <div class="switch" ref="switch">
    <p class="container__text mb16">
      <strong> Бренд-блоки отличаются по ширине (количеству карманов)</strong>
    </p>
    <div class="grid">
      <div
        v-for="(num, i) of [5, 4, 3, 2, 1]"
        :key="i"
        class="item"
        :class="{ active: selected === num }"
        @click="$emit('changeNum', num)"
      >
        <button type="button">{{ num }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Switch",
  props: {
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.switch {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  padding: 18px;
  background: #ffffff;
  border-radius: 2px;
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 44px);
    justify-content: center;
    margin-top: 24px;
    grid-gap: 24px;
    @include onlyMobile {
      margin-top: 16px;
      grid-template-columns: repeat(5, 44px);
      grid-gap: 8px;
    }
  }
  .container__text {
    text-align: center;
    @include onlyMobile {
      text-align: left;
      font-size: 14px;
      line-height: 120%;
    }
  }
  .item {
    button {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      position: relative;
      font-size: 14px;
      &::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border: 2px solid #dbdbdb;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 50%;
        transition: all 0.3s;
      }
      &:hover:before {
        transform: translateY(-50%) translateX(-50%) scale(1.05);
        border-color: #dbdbdb;
      }
      &:active::before {
        transform: translateY(-50%) translateX(-50%) scale(1);
        border-color: #1f2229;
      }
    }
  }
  .item.active {
    button:before {
      border-color: var(--mainColor);
      opacity: 1;
    }
    button {
      background: #eb5893;
      color: #ffffff;
    }
  }
}
</style>
