<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <RulesContent>
          <template #top>
            <h2 class="title">Примеры внешнего оформления</h2>
            <SurveyNumbers :config="config">
              <template #title>
                <h3 class="title">
                  Входная зона <span class="pink">ОБЯЗАТЕЛЬНО</span> должна быть
                  оформлена как минимум одним POSM из ниже перечисленных (при
                  наличии разрешения на размещения POSM) :
                </h3>
              </template>
              <template #info>
                <p class="container__text link-m">
                  <strong>Дополнительно могут быть использованы POSm:</strong>
                </p>
                <ul>
                  <li>Открыто/Закрыто</li>
                  <li>От себя/На себя</li>
                  <li>Павильонная лента</li>
                </ul>
                <p class="container__text link-m">
                  <!-- Расширенный список POSm для оформления точки продаж можно
              посмотреть в
              <router-link to="/" class="link link--blue"
                >Каталоге POSm</router-link
              > -->
                  Расширенный список POSm для оформления точки продаж можно
                  посмотреть в Каталоге POSm
                </p>
              </template>
            </SurveyNumbers>
          </template>
          <template #bottom>
            <h2 class="title">Правила внешнего оформления</h2>
            <p class="container__text--big link-m">
              Важно, чтобы проходящий мимо поток обращал внимание на возможность
              подключения к Tele2.<br />Для этого необходимо правильно подобрать
              и разместить навигационные материалы:
            </p>
            <div class="white-content">
              <div class="grid align-center">
                <div class="grey">
                  <BeforeAfter
                    before="before-1.png"
                    after="after-1.png"
                    offset="0.8"
                  />
                </div>
                <div>
                  <p class="container__text">
                    <strong
                      >При внешнем оформлении необходимо помнить о
                      правилах:</strong
                    >
                  </p>
                  <AttentionIcon
                    :icon="{ name: 'eye.svg', w: 25, h: 19 }"
                    class="m24"
                    text="Фасадные/навигационные таблички и стикеры размещаются на уровне глаз (ниже пояса и выше уровня глаз не размещаются)"
                  />
                  <AttentionIcon
                    :icon="{ name: 'diagonal.png', w: 32, h: 32 }"
                    class="m24"
                    text="Еcли место для размещения POSm больше формата А1 (594х841 мм) - для брендирования необходимо использовать: гуталин, паттерн, фасадный баннер"
                  />
                  <AttentionIcon
                    :icon="{ name: 'sheets.svg', w: 23, h: 24 }"
                    class="m24"
                    text="Одинаковые материалы не дублируются в одной плоскости"
                  />
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'lamp.png', w: 32, h: 32 }"
                    text="Возможность подключения к Tele2 должно быть видно с основного, проходящего рядом со входом, потока людей."
                  />
                  <AttentionIcon :icon="{ name: 'nav.svg', w: 20, h: 19 }">
                    <template #text>
                      <p class="container__text">
                        Для обеспечения внешней навигации к торговой точке можно
                        использовать различного рода
                        <router-link
                          to="/zones?zone=pink"
                          class="link link--blue"
                          >фасадные навигационные POSm</router-link
                        >
                      </p>
                    </template>
                  </AttentionIcon>
                </div>
              </div>
            </div>
          </template>
        </RulesContent>
        <h1 class="title">Зонирование торговых точек</h1>
        <ButtonLink
          :button="{ name: 'Входная группа', path: '/zones?zone=pink' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIcon from "../AttentionIcon.vue";
import BeforeAfter from "../BeforeAfter.vue";
import ButtonLink from "../ButtonLink.vue";
import RulesContent from "../RulesContent.vue";
import SurveyNumbers from "../SurveyNumbers.vue";

export default {
  components: {
    RulesContent,
    AttentionIcon,
    ButtonLink,
    SurveyNumbers,
    BeforeAfter,
  },
  name: "ExternalDesign",
  data() {
    return {
      config: {
        img: "rule-2.png",
        buttons: [
          {
            bg: "#EC6430",
            number: 1,
            left: 88,
            top: 25,
            flag: "Фасадная/навигационная табличка",
            direction: "to-left",
          },
          {
            bg: "#FF59A3",
            number: 2,
            left: 22,
            top: 25,
            flag: "Флаг фасадный",
          },
          {
            bg: "#53AEE2",
            number: 3,
            left: 82,
            top: 48,
            flag: "Стикер на входной группе о возможности подключения",
            direction: "to-left",
          },
          { bg: "#CB80F5", number: 4, left: 54, top: 46, flag: "Стикер бабл" },
        ],
        points: [
          {
            bg: "#EC6430",
            number: 1,
            link: true,
            text: "Фасадная/навигационная табличка",
          },
          {
            bg: "#FF59A3",
            number: 2,
            link: true,
            text: "Флаг фасадный",
          },
          {
            bg: "#53AEE2",
            number: 3,
            link: true,
            text: "Стикер бабл",
          },
          {
            bg: "#CB80F5",
            number: 4,
            link: true,
            text: "Стикер на входной группе о возможности подключения / Акционные продуктовые стикеры (Есим, Твикс, Микс)",
          },
          {
            bg: "#C7E553",
            number: 5,
            link: true,
            text: "Гуталин / Паттерн / Фасадный баннер",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.rule {
  .grey-block {
    padding-bottom: 64px;
    @include tablet {
      padding-bottom: 24px;
    }
  }
  h2.title {
    margin-bottom: 32px;
    @include tablet {
      display: none;
    }
  }
  .white-content {
    padding: 24px;
    background: #ffffff;
    margin-top: 32px;
    img.bs {
      box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
      border-radius: 4px;
    }
    @include tablet {
      margin-top: 24px;
      padding: 0;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding-bottom: 24px;
    }
    &.align-center {
      align-items: center;
    }
    .m24 {
      margin: 24px 0;
      @include tablet {
        margin: 16px 0;
      }
    }
  }
  h3.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 16px;
    @include tablet {
      font-size: 18px;
      line-height: 140%;
    }
    span.pink {
      color: #ff59a3;
    }
  }
  .link-m.container__text {
    margin: 32px 0 8px 0;
    @include tablet {
      margin: 24px 0 8px 0;
    }
    a {
      font-size: 16px;
    }
  }
  h1.title {
    font-family: var(--fontStencil);
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 56px;
    margin-bottom: 16px;
    @include tablet {
      margin-top: 24px;
      font-size: 20px;
      line-height: 120%;
    }
  }
  .button-link {
    max-width: 252px;
    @include onlyMobile {
      max-width: 100%;
    }
  }
  .grey {
    @include tablet {
      margin: 0 -15px;
      padding: 18px 15px 18px 15px;
      background: #f0f1f2;
    }
  }
}
</style>
