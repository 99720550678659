<template>
  <div class="green">
    <div class="white">
      <h2 class="title">Витрины</h2>
      <div class="info">
        <div class="item mb24">
          <h4 class="title mb8">Задача POSm</h4>
          <p class="container__text">
            POSm, размещенные в витринах, усиливают представленность бренда в
            магазине и предоставляют информацию об основных продуктах Tele2.
          </p>
        </div>
        <div class="item">
          <h4 class="title mb8">Примеры POSm</h4>
          <ul class="small">
            <li>Бренд-блок</li>
            <li>Боковой стоппер</li>
            <li>Топпер на витрину</li>
            <li>Шелфтокер со вставкой, вобблер</li>
          </ul>
        </div>
      </div>
      <div class="survey-block info">
        <h5 class="title">Пример размещения POSm:</h5>
        <Survey :config="surveyConfig" />
        <p class="text">
          Полочное пространство и зона выкладки — места непосредственного
          размещения товара для наиболее эффективных POSm. Например,
          бренд-блоков, вобблеров, шелфтокеров и других POSm.
        </p>
        <p class="text">
          Сильный эффект оказывает кросс-выкладка. Например, размещение рядом со
          смартфоном тейблтента с тарифом для смартфона.
        </p>
        <p class="text">
          Яркая реклама в зоне витрин побуждает к покупке, важно визуально
          подчеркнуть наиболее выгодное предложение.
        </p>
      </div>
    </div>
    <h3 class="title">ПОДРОБНЫЕ Правила размещения POSM</h3>
    <div class="grid-buttons">
      <ButtonLink
        :button="{ name: 'Оформление витрины', path: '/rules?rule=6' }"
      />
      <ButtonLink
        :button="{ name: 'Оформление Бренд-блока', path: '/rules?rule=7' }"
      />
    </div>
  </div>
</template>

<script>
import Survey from "../Survey.vue";
import ButtonLink from "@/components/ButtonLink.vue";

export default {
  components: { Survey, ButtonLink },
  name: "ZoneGreen",
  data() {
    return {
      surveyConfig: {
        img: "survey-3.png",
        buttons: [
          {
            color: "green",
            left: 22,
            top: 12,
            flag: "Топпер на витрину",
          },
          {
            color: "green",
            left: 23,
            top: 28,
            flag: "Бренд-блок",
          },
          {
            color: "green",
            left: 23,
            top: 49,
            flag: "Шелфтокер со вставкой",
          },
          {
            color: "green",
            left: 78,
            top: 38,
            flag: "Боковой стоппер",
            direction: "to-left",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.green {
  .item {
    h4.title {
      background: #c7e553;
    }
  }
}
h2.title {
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 32px;
  @include onlyMobile {
    margin-bottom: 16px;
    font-size: 20px;
  }
}
.info {
  padding: 24px;
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 32px;
  @include tablet {
    box-shadow: 0px 8px 16px -5px rgba(0, 0, 0, 0.12);
  }
  @include onlyMobile {
    padding: 16px 16px 24px 16px;
    margin-bottom: 40px;
  }
  h5.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 34px;
  }
  .item {
    h4.title {
      color: #ffffff;
      font-weight: bold;
      font-size: 12px;
      line-height: 120%;
      padding: 4px 8px;
      border-radius: 2px;
      width: fit-content;
    }
  }
}
.survey-block {
  margin-bottom: 56px;
  @include tablet {
    box-shadow: none;
    padding: 0 0 24px 0;
  }
  @include onlyMobile {
    margin-bottom: 24px;
  }
  .text {
    font-size: 16px;
    line-height: 140%;
  }
  & .text:last-child {
    margin: 0;
  }
  & .text {
    margin: 24px 0;
    @include onlyMobile {
      margin: 16px 0;
    }
  }
  h5.title {
    margin-bottom: 24px;
    @include onlyMobile {
      margin-bottom: 16px;
    }
  }
}
h3.title {
  font-size: 24px;
  line-height: 120%;
  font-family: var(--fontStencil);
  text-transform: uppercase;
  margin-bottom: 16px;
  @include onlyMobile {
    font-size: 20px;
    line-height: 120%;
  }
}
.grid-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 36px;
  @include onlyMobile {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
</style>
