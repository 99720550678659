<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <RulesContent>
          <template #top>
            <h2 class="title">Примеры оформления бренд-блока</h2>
            <p class="container__text link-m">
              <strong>Бренд-блок (ББ)</strong> — это пластиковая конструкция для
              визуализации ассортимента тарифных планов Tele2, обязательный
              материал для оформления торговой точки.
            </p>
            <div class="grey">
              <h6 class="title">
                Для каналов LA, LS дополнительно используется вставка с имиджем
                из ниже перечисленных:
              </h6>
              <div class="grid-att">
                <PointNumber
                  :point="{
                    number: 5,
                    bg: '#ffffff',
                    styles: {
                      width: 32,
                      height: 32,
                      borderColor: '#FF59A3',
                      color: '#FF59A3',
                    },
                  }"
                >
                  <template #content>
                    <p class="container__text">
                      Размещается только в бренд-блоке с 5 карманами.
                    </p>
                  </template>
                </PointNumber>
                <AttentionIcon
                  :icon="{ name: 'alert.svg', w: 17, h: 17 }"
                  text="Больше 1 имиджа не размещаем."
                />
              </div>
              <div class="grid-swiper desktop">
                <ButtonLeft />
                <Swiper
                  :pagination="{
                    el: '.swiper-pagination',
                  }"
                  :navigation="{
                    nextEl: '.button-right',
                    prevEl: '.button-left',
                  }"
                  :spaceBetween="36"
                  :slidesPerView="3"
                  :loop="true"
                  :slidesPerGroup="3"
                >
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-1.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-2.png" :full-image="true" />
                      <h3 class="item__title">
                        При продаже SIM-карт с саморегистрацией
                      </h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-3.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-4.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-5.png" :full-image="true" />
                      <h3 class="item__title">При наличии Promo на тариф</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-6.png" :full-image="true" />
                      <h3 class="item__title">При наличии в магазине MNP</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-7.png" :full-image="true" />
                      <h3 class="item__title">При наличии Promo на тариф</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-8.png" :full-image="true" />
                      <h3 class="item__title">При наличии Promo на тариф</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-9.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <div class="swiper-pagination" />
                </Swiper>
                <ButtonRight />
              </div>
              <div class="grid-swiper tablet">
                <Swiper
                  :pagination="{
                    el: '.swiper-pagination',
                  }"
                  :spaceBetween="36"
                  :slidesPerView="1"
                  :loop="true"
                  :autoHeight="true"
                >
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-1.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-2.png" :full-image="true" />
                      <h3 class="item__title">
                        При продаже SIM-карт с саморегистрацией
                      </h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-3.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-4.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-5.png" :full-image="true" />
                      <h3 class="item__title">При наличии Promo на тариф</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-6.png" :full-image="true" />
                      <h3 class="item__title">При наличии в магазине MNP</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-7.png" :full-image="true" />
                      <h3 class="item__title">При наличии Promo на тариф</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-8.png" :full-image="true" />
                      <h3 class="item__title">При наличии Promo на тариф</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <WhiteBlock img="brand-block-9.png" :full-image="true" />
                    </div>
                  </SwiperSlide>
                  <div class="swiper-pagination" />
                </Swiper>
              </div>
            </div>
          </template>
          <template #bottom>
            <h2 class="title">Правила оформления бренд-блока</h2>
            <p class="container__text mobile">
              <strong>Правила размещения:</strong>
            </p>
            <div class="white-content">
              <div class="grid align-center">
                <div class="grey grid-switch">
                  <SwitchNumbers
                    class="a"
                    :selected="selected"
                    @change-num="selected = $event"
                  />
                  <img
                    src="@/assets/img/five.png"
                    v-show="selected === 5"
                    class="b"
                  />
                  <img
                    src="@/assets/img/four.png"
                    v-show="selected === 4"
                    class="b"
                  />
                  <img src="@/assets/img/three.png" v-show="selected === 3" />
                  <img
                    src="@/assets/img/two.png"
                    v-show="selected === 2"
                    class="b"
                  />
                  <img
                    src="@/assets/img/one.png"
                    v-show="selected === 1"
                    class="b"
                  />
                </div>
                <div>
                  <p class="container__text">
                    <strong
                      >Ширина вставок в карманы в ББ единая и составляет 148
                      мм.<br />По высоте вставок существует 2 размера: 168 мм,
                      188 мм.</strong
                    >
                  </p>
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'attached-file.svg', w: 20, h: 24 }"
                    text="ББ - размещается только в витринах или в кассовой зоне. В остальных зонах размещать запрещено."
                  />
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'diagonal.png', w: 32, h: 32 }"
                    text="Размер бренд-блока, размещаемого в торговой точке, определяется размерами витрины (или иного места размещения), либо утверждается магазином."
                  />
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'sheets.svg', w: 23, h: 24 }"
                    text="Если нет места для размещения бренд-блока в витрине или кассовой зоне, в других зонах необходимо использовать альтернативный материал: картонный муляж, тейбл-тент&nbsp;А5."
                  />
                  <AttentionIcon
                    :icon="{ name: 'lamp.png', w: 32, h: 32 }"
                    text="При размещении бренд-блока в витрине, для дополнительной визуализации рекомендуется размещать шелфтокер под бренд-блоком."
                  />
                </div>
              </div>
            </div>
          </template>
        </RulesContent>
        <h1 class="title">Зонирование торговых точек</h1>
        <ButtonLink :button="{ name: 'Витрины', path: '/zones?zone=green' }" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Pagination, Navigation]);
import AttentionIcon from "../AttentionIcon.vue";
import ButtonLink from "../ButtonLink.vue";
import RulesContent from "../RulesContent.vue";
import WhiteBlock from "../WhiteBlock.vue";
import ButtonLeft from "@/components/ButtonLeft.vue";
import ButtonRight from "@/components/ButtonRight.vue";
import PointNumber from "../PointNumber.vue";
import SwitchNumbers from "../SwitchNumbers.vue";

export default {
  components: {
    RulesContent,
    AttentionIcon,
    ButtonLink,
    Swiper,
    SwiperSlide,
    WhiteBlock,
    ButtonLeft,
    ButtonRight,
    PointNumber,
    SwitchNumbers,
  },
  name: "BrandBlock",
  data() {
    return {
      selected: 5,
    };
  },
};
</script>

<style lang="scss" scoped>
.rule {
  .white {
    @include tablet {
      padding-bottom: 1px;
    }
  }
  .grey-block {
    padding-bottom: 64px;
    @include tablet {
      padding-bottom: 24px;
    }
  }
  h2.title {
    margin-bottom: 32px;
    @include tablet {
      display: none;
    }
  }
  .white-content {
    padding: 24px;
    background: #ffffff;
    margin-top: 32px;
    img.bs {
      box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
      border-radius: 4px;
    }
    @include tablet {
      margin-top: 24px;
      padding: 0;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding-bottom: 24px;
    }
    &.align-center {
      align-items: center;
    }
    .m24 {
      margin: 24px 0;
      @include tablet {
        margin: 16px 0;
      }
    }
  }
  h3.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 16px;
    @include tablet {
      font-size: 18px;
      line-height: 140%;
    }
    span {
      color: #ff59a3;
    }
  }
  .link-m.container__text {
    margin: 32px 0 8px 0;
    @include tablet {
      margin: 24px 0;
    }
    a {
      font-size: 16px;
    }
  }
  h1.title {
    font-family: var(--fontStencil);
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 56px;
    margin-bottom: 16px;
    @include tablet {
      margin-top: 24px;
      font-size: 20px;
      line-height: 120%;
    }
  }
  .button-link {
    max-width: 252px;
    @include onlyMobile {
      max-width: 100%;
    }
  }
  .grey {
    @include tablet {
      margin: 0 -15px;
      padding: 18px 15px 18px 15px;
      background: #f0f1f2;
    }
  }
  &-white {
    padding: 18px 20px 14px 16px;
  }
  h6.title {
    font-size: 16px;
    line-height: 140%;
    font-family: var(--fontSerif);
    margin: 32px 0 42px 0;
    @include tablet {
      margin: 0 0 18px 0;
    }
  }
  .grid-swiper {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-gap: 20px;
    align-items: center;
    margin: 0 -61px 43px -63px;
    @include tablet {
      grid-template-columns: 1fr;
      margin: 0;
    }
  }
  .swiper-container {
    width: 100%;
    padding: 20px 20px 30px 20px;
    margin: 0 -20px;
    @include tablet {
      padding: 15px;
      margin: 0 -15px;
    }
    .swiper-slide {
      width: fit-content;
    }
    .swiper-pagination {
      bottom: -10px !important;
      @include tablet {
        bottom: -5px !important;
      }
    }
  }
  .item {
    @include tablet {
      width: fit-content;
      margin: 0 auto 24px auto;
    }
    &__title {
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      margin-top: 24px;
    }
  }
  .grid-att {
    display: grid;
    grid-template-columns: repeat(2, 407px);
    grid-gap: 36px;
    margin-bottom: 32px;
    @include tablet {
      grid-template-columns: 1fr;
      margin-top: 32px;
      margin-bottom: 24px;
      grid-gap: 18px;
    }
  }
  .grid-switch {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    grid-template-areas: "a" "b";
    @include tablet {
      grid-row-gap: 18px;
      grid-template-areas: "b" "a";
    }
    .a {
      grid-area: a;
    }
    .b {
      grid-area: b;
    }
    img {
      margin: 0 auto;
      max-height: 141px;
      width: auto;
      max-width: 503px;
      @include tablet {
        max-height: 83px;
      }
    }
  }
}
.desktop {
  @include tablet {
    display: none !important;
  }
}
.tablet {
  display: none !important;
  @include tablet {
    display: grid !important;
  }
}
.mobile {
  display: none;
  @include onlyMobile {
    display: block;
  }
}
</style>
