<template>
  <div class="survey-num">
    <div class="white-content">
      <div class="grid">
        <div class="survey">
          <img
            v-if="!Array.isArray(config.img)"
            :src="require(`@/assets/img/${config.img}`)"
            class="bs"
          />
          <div v-else>
            <img
              v-for="(img, i) of config.img"
              v-show="config.imgActive === i"
              :key="i"
              :src="require(`@/assets/img/${img}`)"
              class="bs"
            />
          </div>
          <ButtonNumber
            v-for="(button, i) of config.buttons"
            :key="i"
            :button="{ ...button, active: active == button.number }"
            @enter="active = $event"
          />
        </div>
        <div>
          <slot name="title" />
          <PointNumber
            v-for="(point, i) of config.points"
            :key="i"
            :point="{
              ...point,
              active: active == point.number,
            }"
            @isActive="active = $event"
          >
            <template #content><strong v-html="point.text"></strong></template>
          </PointNumber>
          <slot name="info" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonNumber from "./ButtonNumber.vue";
import PointNumber from "./PointNumber.vue";

export default {
  components: {
    PointNumber,
    ButtonNumber,
  },
  name: "SurveyNumbers",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.survey {
  position: relative;
  width: fit-content;
  height: fit-content;
}
.survey-num {
  .white-content {
    padding: 24px;
    background: #ffffff;
    margin-top: 32px;
    img.bs {
      box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
      border-radius: 4px;
    }
    @include tablet {
      margin-top: 24px;
      padding: 0;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding-bottom: 24px;
    }
    .m24 {
      margin: 24px 0;
      @include tablet {
        margin: 16px 0;
      }
    }
  }
  .number {
    margin-bottom: 16px;
  }
}
</style>
