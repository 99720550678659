<template>
  <div class="switch-mobile" :class="{ showed: isShowed }">
    <div class="switch-mobile__container">
      <div class="switch-mobile__grid">
        <div
          v-for="(zone, i) of zones"
          :key="i"
          class="item"
          :class="{ active: selected === zone.color }"
          @click="$emit('changeZone', zone.color)"
        >
          <button :class="zone.color" type="button" />
          <p>{{ zone.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  namr: "SwitchMobile",
  props: {
    selected: {
      type: String,
      required: true,
    },
    isShowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zones: [
        {
          color: "pink",
          title: "Входная группа",
        },
        {
          color: "blue",
          title: "Торговый зал",
        },
        {
          color: "green",
          title: "Витрины",
        },
        {
          color: "orange",
          title: "Кассовая зона",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.switch-mobile {
  position: fixed;
  top: 45px;
  height: 60px;
  width: 100%;
  background: #f0f1f2;
  box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.12);
  z-index: 99;
  transform: translateY(-60px);
  opacity: 0;
  transition: all 0.3s;
  &.showed {
    transform: translateY(0);
    opacity: 1;
  }
  &__container {
    max-width: 255px;
    margin: 0 auto;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 54px);
    grid-gap: 13px;
  }
  .item {
    button {
      background: #ffffff;
      border: 2px solid #bfc2c4;
      width: 100%;
      height: 12px;
      border-radius: 24px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 46px;
        height: 4px;
        border-radius: 24px;
      }
      &.pink::after {
        background: #ff59a3;
      }
      &.blue::after {
        background: #53aee2;
      }
      &.green::after {
        background: #c7e553;
      }
      &.orange::after {
        background: #ec6430;
      }
    }
    p {
      font-size: 11px;
      line-height: 120%;
      text-align: center;
      margin-top: 6px;
      color: #bfc2c4;
    }
    &.active {
      button {
        border: 2px solid #1f2229;
      }
      p {
        color: var(--mainColor);
      }
    }
  }
}
</style>
