import request from "./request";

export function getRegions() {
  return request({
    url: "/regions/",
    method: "get",
  });
}

export function getDocTypes() {
  return request({
    url: "/types/",
    method: "get",
  });
}

export function getDocSections(p) {
  return request({
    url: "/sections/",
    method: "get",
    params: p,
  });
}

export function getDocs(p) {
  return request({
    url: "/documents/",
    method: "get",
    params: p,
  });
}
