<template>
  <div class="survey">
    <img :src="require(`../assets/img/${config.img}`)" />
    <ButtonSurvey
      v-for="(button, i) of config.buttons"
      :key="i"
      :button="button"
    />
  </div>
</template>

<script>
import ButtonSurvey from "./ButtonSurvey.vue";

export default {
  components: { ButtonSurvey },
  name: "Survey",
  props: {
    config: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  width: fit-content;
  height: fit-content;
  position: relative;
  img {
    box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
    border-radius: 4px;
  }
}
</style>
