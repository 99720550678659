<template>
  <div ref="container" class="container flex-sticky">
    <div ref="static" class="static">
      <slot name="static" />
    </div>
    <div ref="scroll" class="scroll">
      <slot name="scroll" />
    </div>
  </div>
</template>

<script>
export default {
  name: "StickyComponent",
  mounted() {
    if (this.device == "desktop")
      window.addEventListener("scroll", this.scrollWindow, false);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  methods: {
    scrollWindow() {
      if (this.$refs.container.getBoundingClientRect().top <= 84) {
        this.$refs.static.classList.add("sticky");
      } else {
        this.$refs.static.classList.remove("sticky");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  z-index: 10;
  top: 85px;
  float: left;
  @include tablet {
    position: relative;
    top: auto;
    z-index: 1;
  }
}
.stop {
  position: relative;
  z-index: 10;
}
.static {
  float: left;
  @include tablet {
    max-width: 100%;
    float: none;
    width: 100%;
  }
}
.scroll {
  margin-left: auto;
  max-width: 540px;
  @include tablet {
    max-width: 100%;
    margin-left: 0;
    width: 100%;
  }
}
.container.flex-sticky {
  @include tablet {
    max-width: 100%;
    padding: 0;
  }
}
.flex-sticky {
  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
