<template>
  <div class="attention-icon">
    <div class="icon">
      <img
        :src="require(`@/assets/icon/${icon.name}`)"
        alt="icon"
        :width="icon.w"
        :height="icon.h"
      />
    </div>
    <p v-html="text" v-if="text" class="container__text" />
    <slot v-else name="text" />
  </div>
</template>

<script>
export default {
  name: "AttentionIcon",
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.attention-icon {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 8px;
  a.link {
    font-size: 16px !important;
  }
  img {
    width: auto;
    margin: 0 auto;
  }
}
</style>
