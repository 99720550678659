<template>
  <div class="formats-la grey-block">
    <div class="container">
      <p class="container__text">
        Альтернативные торговые точки (FА/LА) специализируются в первую очередь
        на продаже других категорий товаров, не относящиеся напрямую к продаже
        SIM-карт и подключению к услугам мобильной связи. Например: продажа
        бытовой техники, магазин продуктов, услуги почты
      </p>
      <div class="grid desktop">
        <div class="item">
          <WhiteBlock img="formats-4.png" />
          <h3 class="item__title">Магазин</h3>
          <ul>
            <li><strong>Площадь:</strong><br />от 50 до 300 м².</li>
            <li>
              <strong>Характеристики:</strong><br />в основном
              продовольственные/хозяйственные магазины или
              хозяйственные/канцелярия.
            </li>
            <li>
              <strong>Тип обслуживания:</strong><br />через прилавок или
              самообслуживание (мини-маркет).
            </li>
          </ul>
        </div>
        <div class="item">
          <WhiteBlock img="formats-5.png" />
          <h3 class="item__title">АЗС</h3>
          <ul>
            <li><strong>Площадь:</strong><br />от 50 до 300 м².</li>
            <li>
              <strong>Характеристики:</strong><br />торговые точки на территории
              АЗС.
            </li>
            <li>
              <strong>Тип обслуживания:</strong><br />предусматривают прямой
              контакт покупателя с товаром.
            </li>
          </ul>
        </div>
        <div class="item">
          <WhiteBlock img="formats-6.png" />
          <h3 class="item__title">Киоск</h3>
          <ul>
            <li><strong>Площадь:</strong><br />без торгового зала.</li>
            <li>
              <strong>Характеристики:</strong><br />магазины по специализациям –
              продовольственные, непродовольственные, смешанного типа,
              специальные (газетные, табачные).
            </li>
            <li>
              <strong>Тип обслуживания:</strong><br />производится только через
              окно.
            </li>
          </ul>
        </div>
      </div>
      <div class="grid tablet">
        <Swiper
          :pagination="{
            el: '.swiper-pagination',
          }"
          :autoHeight="true"
          :spaceBetween="23"
          :slidesPerView="1"
        >
          <SwiperSlide>
            <div class="item">
              <WhiteBlock img="formats-4.png" />
              <h3 class="item__title">Магазин</h3>
              <ul>
                <li><strong>Площадь:</strong><br />от 50 до 300 м².</li>
                <li>
                  <strong>Характеристики:</strong><br />в основном
                  продовольственные/хозяйственные магазины или
                  хозяйственные/канцелярия.
                </li>
                <li>
                  <strong>Тип обслуживания:</strong><br />через прилавок или
                  самообслуживание (мини-маркет).
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="item">
              <WhiteBlock img="formats-5.png" />
              <h3 class="item__title">АЗС</h3>
              <ul>
                <li><strong>Площадь:</strong><br />от 50 до 300 м².</li>
                <li>
                  <strong>Характеристики:</strong><br />торговые точки на
                  территории АЗС.
                </li>
                <li>
                  <strong>Тип обслуживания:</strong><br />предусматривают прямой
                  контакт покупателя с товаром.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="item">
              <WhiteBlock img="formats-6.png" />
              <h3 class="item__title">Киоск</h3>
              <ul>
                <li><strong>Площадь:</strong><br />без торгового зала.</li>
                <li>
                  <strong>Характеристики:</strong><br />магазины по
                  специализациям – продовольственные, непродовольственные,
                  смешанного типа, специальные (газетные, табачные).
                </li>
                <li>
                  <strong>Тип обслуживания:</strong><br />производится только
                  через окно.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <div class="swiper-pagination" />
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import WhiteBlock from "../WhiteBlock.vue";
SwiperCore.use([Pagination]);

export default {
  components: { WhiteBlock, Swiper, SwiperSlide },
  name: "FormatsLS",
};
</script>

<style lang="scss" scoped>
.formats-la {
  .container__text {
    max-width: 731px;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 348px);
    grid-gap: 36px;
    margin-top: 24px;
    @include tablet {
      grid-template-columns: 1fr;
    }
  }
  .item {
    width: 348px;
    @include tablet {
      margin: 0 auto;
    }
    @include onlyMobile {
      width: 290px;
    }
    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 140%;
      margin: 24px 0 8px 0;
      @include onlyMobile {
        margin: 16px 0 8px 0;
      }
    }
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: grid;
    }
  }
  .swiper-container {
    margin: 0 -15px;
    padding: 0 15px 26px 15px;
    .swiper-pagination {
      bottom: -16px !important;
    }
  }
}
</style>
