<template>
  <div class="pink">
    <div class="white">
      <div class="attention">
        <div class="title">
          <div class="icon">
            <img src="@/assets/icon/alert.svg" />
          </div>
          <p>POSm</p>
        </div>
        <p class="container__text">
          <strong>
            Это рекламные материалы, которые помогают продвигать продукцию в
            местах продаж.
          </strong>
        </p>
      </div>
      <h2 class="title">Входная зона / Внешнее оформление торговой точки</h2>
      <div class="info">
        <div class="item mb24">
          <h4 class="title mb8">Задача POSm</h4>
          <p class="container__text">
            Акцентировать внимание проходящего потока людей на возможность
            подключения к Tele2 в магазине
          </p>
        </div>
        <div class="item">
          <h4 class="title mb8">Задача POSm</h4>
          <ul class="small">
            <li>Навигационная табличка со стрелкой</li>
            <li>Флаг навесной</li>
            <li>Стикер на входной группе о возможности подключения</li>
            <li>Стикер бабл</li>
          </ul>
        </div>
      </div>
      <div class="survey-block info">
        <h5 class="title">Пример размещения POSm:</h5>
        <Survey :config="surveyConfig" />
        <p class="text">
          Это пространство для активного привлечения внимания потенциальных
          покупателей. Лучше всего в этой зоне работает крупногабаритная
          реклама, заметная на расстоянии. Перед входом в магазин следует
          разместить штендеры, ростовые фигуры.
        </p>
        <p class="text">
          Это пространство для активного привлечения внимания потенциальных
          покупателей. Лучше всего в этой зоне работает крупногабаритная
          реклама, заметная на расстоянии. Перед входом в магазин следует
          разместить штендеры, ростовые фигуры.
        </p>
      </div>
    </div>
    <h3 class="title">ПОДРОБНЫЕ Правила размещения POSM</h3>
    <div class="grid-buttons">
      <ButtonLink
        :button="{ name: 'Внешнее оформление', path: '/rules?rule=3' }"
      />
      <ButtonLink
        :button="{ name: 'Оформление внутри ТЦ', path: '/rules?rule=4' }"
      />
    </div>
  </div>
</template>

<script>
import Survey from "../Survey.vue";
import ButtonLink from "@/components/ButtonLink.vue";

export default {
  components: { Survey, ButtonLink },
  name: "ZonePink",
  data() {
    return {
      surveyConfig: {
        img: "survey-1.png",
        buttons: [
          {
            color: "pink",
            left: 23,
            top: 24,
            flag: "Флаг навесной",
          },
          {
            color: "pink",
            left: 53,
            top: 44,
            flag: "Стикер на входной группе",
            direction: "to-left",
          },
          {
            color: "pink",
            left: 86,
            top: 24,
            flag: "Навигационная табличка со стрелкой",
            direction: "to-left",
          },
          {
            color: "pink",
            left: 80,
            top: 49,
            flag: "Стикер бабл",
            direction: "to-left",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.pink {
  .item {
    h4.title {
      background: #ff59a3;
    }
  }
}
.attention {
  padding: 16px 24px 24px 24px;
  background: #ffffff;
  border: 2px dashed #b7b7b7;
  border-radius: 2px;
  margin-bottom: 32px;
  @include onlyMobile {
    padding: 16px 16px 24px 16px;
  }
  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #ff59a3;
    margin-bottom: 8px;
  }
  .icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
h2.title {
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 32px;
  @include onlyMobile {
    margin-bottom: 16px;
    font-size: 20px;
  }
}
.info {
  padding: 24px;
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 32px;
  @include tablet {
    box-shadow: 0px 8px 16px -5px rgba(0, 0, 0, 0.12);
  }
  @include onlyMobile {
    padding: 16px 16px 24px 16px;
    margin-bottom: 40px;
  }
  h5.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 34px;
  }
  .item {
    h4.title {
      color: #ffffff;
      font-weight: bold;
      font-size: 12px;
      line-height: 120%;
      padding: 4px 8px;
      border-radius: 2px;
      width: fit-content;
    }
  }
}
.survey-block {
  margin-bottom: 56px;
  @include tablet {
    box-shadow: none;
    padding: 0 0 24px 0;
  }
  @include onlyMobile {
    margin-bottom: 24px;
  }
  .text {
    font-size: 16px;
    line-height: 140%;
  }
  & .text:last-child {
    margin: 0;
  }
  & .text {
    margin: 24px 0;
    @include onlyMobile {
      margin: 16px 0;
    }
  }
  h5.title {
    margin-bottom: 24px;
    @include onlyMobile {
      margin-bottom: 16px;
    }
  }
}
h3.title {
  font-size: 24px;
  line-height: 120%;
  font-family: var(--fontStencil);
  text-transform: uppercase;
  margin-bottom: 16px;
  @include onlyMobile {
    font-size: 20px;
    line-height: 120%;
  }
}
.grid-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 36px;
  @include onlyMobile {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
</style>
