<template>
  <main class="home">
    <div class="container center">
      <div class="home-grid">
        <h1 class="home-title">
          Правила оформления точек продаж локального канала
        </h1>
        <div class="cards-grid">
          <FlipCard v-for="(card, i) of cards" :key="i" :card="card" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FlipCard from "@/components/FlipCard.vue";
export default {
  components: { FlipCard },
  name: "Home",
  data() {
    return {
      cards: [
        {
          img: "flip-1.png",
          title: "Форматы торговых точек",
          back_text:
            "В разделе описаны различия между форматами торговых точек в разрезе по каналам FS/LS и FA/LA",
          go_to: "/formats",
        },
        {
          img: "flip-2.png",
          title: "Зонирование торговых точек",
          back_text:
            "В этом разделе подробно описано из каких зон для оформления состоит торговая точка",
          go_to: "/zones",
        },
        {
          img: "flip-3.png",
          title: "Правила размещения POSM",
          back_text:
            "В этом разделе подробно отражены правила и примеры оформления каждой зоны торговой точки",
          go_to: "/rules",
        },
        {
          img: "flip-4.png",
          title: "Требования к&nbsp;фотоотчетам",
          back_text:
            "В этом разделе отражены требования к фотоотчетам торговых точек после их оформления",
          go_to: "/reports",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 10px 0;
  background-size: 100%;
  background-position-y: bottom;
  margin-top: -45px;
  @include tablet {
    padding: 24px 0;
    background: none;
    margin-top: 0px;
  }
  .center {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: center;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 18px;
    }
  }
  &-title {
    font-family: "Standard CT Stencil";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 140%;
    text-transform: uppercase;
    max-width: 828px;
    @include tablet {
      max-width: none;
    }
    @include onlyMobile {
      font-size: 24px;
      line-height: 120%;
    }
  }
}
.cards-grid {
  display: grid;
  grid-template-columns: repeat(4, 252px);
  grid-gap: 36px;
  @include tablet {
    grid-template-columns: repeat(2, 252px);
    justify-content: center;
  }
  @include onlyMobile {
    grid-template-columns: repeat(2, minmax(136px, 252px));
    grid-template-rows: repeat(2, calc(50vw - 25px));
    grid-gap: 18px;
  }
}
</style>
