<template>
  <nav class="tabs" ref="tab">
    <Swiper
      :spaceBetween="18"
      :slidesPerView="'auto'"
      :slidesOffsetBefore="18"
      freeMode
      @slideChange="slideChange"
      @swiper="onSwiper"
    >
      <SwiperSlide v-for="(tab, i) of tabs" :key="i">
        <button
          type="button"
          :ref="`tab${tab.rule}`"
          :class="['tabs-item', { active: linePosition == tab.rule }]"
          @click="
            $router.push({ query: { rule: tab.rule } }), $emit('tab', tab.rule)
          "
        >
          <span>{{ tab.title }}</span>
        </button>
      </SwiperSlide>
      <div v-show="!activeIndex" class="tabs-shadow" />
      <div v-show="activeIndex" class="tabs-shadow right" />
    </Swiper>
  </nav>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Pagination]);

export default {
  components: { Swiper, SwiperSlide },
  name: "Tabs",
  data() {
    return {
      linePosition: 1,
      tabs: [
        {
          title: "Общие правила",
          rule: 1,
        },
        {
          title: "Обязательные POSM",
          rule: 2,
        },
        {
          title: "Внешнее оформление",
          rule: 3,
        },
        {
          title: "Оформление внутри ТЦ",
          rule: 4,
        },
        {
          title: "Оформление торгового зала",
          rule: 5,
        },
        {
          title: "Оформление витрины",
          rule: 6,
        },
        {
          title: "Бренд-блок",
          rule: 7,
        },
        {
          title: "Оформление прикассовой зоны",
          rule: 8,
        },
      ],
      swiper: null,
      activeIndex: 0,
      swiperTranslate: 0,
    };
  },
  mounted() {
    let rule = +this.$route.query.rule;
    this.linePosition = rule || 1;
    if (rule < 1 || rule > 8 || isNaN(rule)) this.linePosition = 1;
  },
  watch: {
    "$route.query": function (val) {
      this.linePosition = +val.rule || 1;
      this.moveLine(this.linePosition);
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.activeIndex = swiper.activeIndex;
      if (
        (+this.$route.query.rule === this.tabs.length &&
          this.device === "desktop") ||
        this.device !== "desktop"
      )
        this.slideTo(+this.$route.query.rule - 1 || 0);
    },
    moveLine(pos) {
      this.linePosition = pos;
    },
    slideTo(num) {
      this.swiper.slideTo(num);
    },
    slideChange(swiper) {
      this.activeIndex = swiper.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: grid;
  grid-template-columns: 1fr;
  background: #f1f1f1;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #d3d9df;
    left: 0;
    bottom: 0;
    z-index: 0;
  }

  @include tablet {
    margin: 0 -15px;
    padding: 0 15px;
  }
  &-item {
    font-size: 14px;
    line-height: 18px;
    color: #7c8792;
    white-space: nowrap;
    transition: all 0.3s;
    position: relative;

    &:hover {
      color: var(--mainColor);
    }
    &.active {
      color: var(--mainColor);
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--mainColor);
        left: 0;
        bottom: -13px;
        z-index: 10;
        transition: all 0.3s;
      }
    }
  }
  &-shadow {
    position: absolute;
    width: 224px;
    height: 26px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(
      270deg,
      #f0f1f2 0%,
      rgba(240, 241, 242, 0) 100%
    );
    pointer-events: none;
    z-index: 1;
    &.right {
      right: auto;
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }
    @include tablet {
      display: none;
    }
  }
  .swiper-container {
    padding: 13px 0;
    width: 100%;
  }
  .swiper-slide {
    width: fit-content;
  }
  .line {
    position: absolute;
    height: 2px;
    background: var(--mainColor);
    bottom: 0;
    transition: all 0.3s;
    @include tablet {
      display: none;
    }
  }
}
</style>
