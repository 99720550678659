<template>
  <button class="button-left" type="button">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19" stroke="#34383F" stroke-width="2" />
      <path
        d="M11.2929 19.2929C10.9024 19.6834 10.9024 20.3166 11.2929 20.7071L17.6569 27.0711C18.0474 27.4616 18.6805 27.4616 19.0711 27.0711C19.4616 26.6805 19.4616 26.0474 19.0711 25.6569L13.4142 20L19.0711 14.3431C19.4616 13.9526 19.4616 13.3195 19.0711 12.9289C18.6805 12.5384 18.0474 12.5384 17.6569 12.9289L11.2929 19.2929ZM28 21C28.5523 21 29 20.5523 29 20C29 19.4477 28.5523 19 28 19V21ZM12 21L28 21V19L12 19V21Z"
        fill="black"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "ButtonLeft",
};
</script>

<style lang="scss" scoped>
.button-left {
  svg path,
  svg circle {
    transition: all 0.3s;
  }
  &:hover {
    svg path {
      fill: #797a7f;
    }
    svg circle {
      stroke: #797a7f;
    }
  }
}
</style>
