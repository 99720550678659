<template>
  <a class="button-back" :href="publicPath">
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1739 7.13393L2.84006 7.13393L8.22102 1.47835C8.37061 1.31947 8.46212 1.10364 8.46212 0.865824C8.46212 0.628009 8.37011 0.412178 8.22053 0.252802C8.0739 0.0969243 7.86725 0 7.63796 0C7.40866 0 7.20201 0.0969243 7.05538 0.252802L0.2411 7.41621C0.0920117 7.57508 0 7.79091 0 8.02823C0 8.26554 0.0920117 8.48137 0.241592 8.64075L7.00076 15.7457C7.14739 15.9026 7.35405 16 7.58334 16C7.81263 16 8.01929 15.9026 8.16592 15.7457C8.3155 15.5863 8.40751 15.3705 8.40751 15.1332C8.40751 14.8959 8.3155 14.68 8.16592 14.5207L2.78545 8.86457L19.1734 8.86457C19.6329 8.84959 20 8.46789 20 7.99875C20 7.52962 19.6329 7.14792 19.1748 7.13293L19.1739 7.13393Z"
        fill="#1F2229"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "ButtonBack",
  computed: {
    publicPath() {
      return process.env.VUE_APP_PUBLIC_PATH;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-back {
  display: inline-block;
  margin-bottom: 18px;
}
</style>
