<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <RulesContent>
          <template #top>
            <h2 class="title">Примеры оформления торгового зала</h2>
            <SurveyNumbers :config="config">
              <template #title>
                <h3 class="title">
                  Зона торгового зала <span>ОБЯЗАТЕЛЬНО</span> должна быть
                  оформлена как минимум одним POSM из ниже перечисленных:
                </h3>
              </template>
              <template #info>
                <p class="container__text link-m">
                  <strong
                    >Также, при возможности разместите следующие POSm:</strong
                  >
                </p>
                <ul>
                  <li>Напольные стикеры</li>
                  <li>Уголок потребителя</li>
                </ul>
                <div class="container__text link-m">
                  Каждая торговая точка является уникальной, поэтому необходимо
                  дополнительно убедиться, в правильности размещения блока
                  рекламных материалов Tele2.
                </div>
                <p class="container__text link-m">
                  <!-- Расширенный список POSm для оформления точки продаж можно
              посмотреть в
              <router-link to="/" class="link link--blue"
                >Каталоге POSm</router-link
              > -->
                  Расширенный список POSm для оформления точки продаж можно
                  посмотреть в Каталоге POSm
                </p>
              </template>
            </SurveyNumbers>
          </template>
          <template #bottom>
            <h2 class="title">Правила оформления торгового зала</h2>
            <p class="container__text--big link-m">
              <strong
                >В торговом зале необходимо выделять возможность подключений к
                Tele2.</strong
              ><br />POSm в торговом зале должны быть заметны, читабельны и
              размещаться в «горячих» местах.
            </p>
            <div class="white-content">
              <div class="grid align-center">
                <div class="grey">
                  <BeforeAfter
                    before="before-3.png"
                    after="after-3.png"
                    offset="0.8"
                  />
                  <div class="white-block rule-white m24 tablet">
                    Размещение POSm на выходе является не эффективным
                    применением.
                  </div>
                </div>
                <div>
                  <div class="white-block rule-white m24 desktop">
                    Размещение POSm на выходе является не эффективным
                    применением.
                  </div>
                  <p class="container__text">
                    <strong
                      >При оформлении торгового зала необходимо помнить
                      следующие правила:</strong
                    >
                  </p>
                  <AttentionIcon
                    class="m24"
                    :icon="{
                      name: 'lamp.png',
                      w: device === 'desktop' ? 32 : 24,
                      h: device === 'desktop' ? 32 : 24,
                    }"
                    text="Постеры не размещаются ниже пояса. Исключение: если в этой зоне представлены другие операторы и нет других мест для размещения."
                  />
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'eye.svg', w: 25, h: 19 }"
                    text="POSm должны быть на виду в «горячих зонах», но при этом не должны загораживать собой товары другого бренда или категории."
                  />
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'attached-file.svg', w: 20, h: 24 }"
                    text="Внешний вид рекламных конструкций должен быть чистым и опрятным, без пятен, загнутых концов и некачественной печати."
                  />
                  <AttentionIcon
                    :icon="{ name: 'sheets.svg', w: 23, h: 24 }"
                    text="Не следует загромождать торговое пространство. Количество рекламных материалов должно быть умеренным."
                  />
                </div>
              </div>
            </div>
          </template>
        </RulesContent>
        <h1 class="title">Зонирование торговых точек</h1>
        <ButtonLink
          :button="{ name: 'Торговый зал', path: '/zones?zone=blue' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIcon from "../AttentionIcon.vue";
import BeforeAfter from "../BeforeAfter.vue";
import ButtonLink from "../ButtonLink.vue";
import RulesContent from "../RulesContent.vue";
import SurveyNumbers from "../SurveyNumbers.vue";

export default {
  components: {
    RulesContent,
    AttentionIcon,
    ButtonLink,
    SurveyNumbers,
    BeforeAfter,
  },
  name: "ShoppingRoomDesign",
  data() {
    return {
      config: {
        img: "rule-4.png",
        buttons: [
          {
            bg: "#EC6430",
            number: 1,
            left: 11,
            top: 21,
            flag: "Рамки с постерами",
          },
          {
            bg: "#FF59A3",
            number: 2,
            left: 63,
            top: 10,
            flag: "Подвесной мобайл",
          },
        ],
        points: [
          {
            bg: "#EC6430",
            number: 1,
            link: true,
            text: "Постеры/Постеры в рамках",
            desc: "рекомендуется использовать 1 постер более крупного формата, взамен нескольким постерам меньшего формата. Наиболее эффективные места для размещения постеров: прикассовая зона за продавцом, зона рядом с основным товаром магазина.",
          },
          {
            bg: "#FF59A3",
            number: 2,
            link: true,
            text: "Подвесной мобайл",
            desc: "дает возможность увеличения присутствия бренда Tele2 в торговом пространстве. Наиболее эффективные места размещения: кассовая зона и зона выкладки Tele2.",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.rule {
  .grey-block {
    padding-bottom: 64px;
    @include tablet {
      padding-bottom: 24px;
    }
  }
  h2.title {
    margin-bottom: 32px;
    @include tablet {
      display: none;
    }
  }
  .white-content {
    padding: 24px;
    background: #ffffff;
    margin-top: 32px;
    img.bs {
      box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
      border-radius: 4px;
    }
    @include tablet {
      margin-top: 24px;
      padding: 0;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding-bottom: 24px;
    }
    &.align-center {
      align-items: center;
    }
    .m24 {
      margin: 24px 0;
      @include tablet {
        margin: 16px 0;
      }
    }
  }
  h3.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 16px;
    @include tablet {
      font-size: 18px;
      line-height: 140%;
    }
    span {
      color: #ff59a3;
    }
  }
  .link-m.container__text {
    margin: 32px 0 8px 0;
    @include tablet {
      margin: 24px 0 8px 0;
    }
    a {
      font-size: 16px;
    }
  }
  h1.title {
    font-family: var(--fontStencil);
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 56px;
    margin-bottom: 16px;
    @include tablet {
      margin-top: 24px;
      font-size: 20px;
      line-height: 120%;
    }
  }
  .button-link {
    max-width: 252px;
    @include onlyMobile {
      max-width: 100%;
    }
  }
  .grey {
    @include tablet {
      margin: 0 -15px;
      padding: 18px 15px 18px 15px;
      background: #f0f1f2;
    }
  }
  &-white {
    padding: 18px 20px 14px 16px;
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
</style>
