<template>
  <main class="content">
    <div class="container">
      <ButtonBack />
    </div>
    <div class="container">
      <h1 class="content-title">
        <slot name="title" />
      </h1>
    </div>
    <slot name="main" />
  </main>
</template>

<script>
import ButtonBack from "./ButtonBack.vue";
export default {
  components: { ButtonBack },
  name: "Content",
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 24px;
  @include onlyMobile {
    padding-top: 19px;
  }
  &-title {
    font-family: var(--fontStencil);
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 18px;
    @include onlyMobile {
      font-size: 24px;
      line-height: 120%;
    }
  }
}
</style>
