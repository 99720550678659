<template>
  <button type="button" @click="$emit('open-modal')">
    <div class="card" :style="config.style">
      <div class="img">
        <img :src="config.img" />
      </div>
      <p>{{ config.title }}</p>
    </div>
  </button>
</template>

<script>
export default {
  name: "Card",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 252px;
  height: 232px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding-bottom: 20px;
  text-align: left;
  @include onlyMobile {
    width: 136px;
    height: 128px;
    // padding-bottom: 8px;
  }
  .img {
    width: 100%;
    height: 146px;
    overflow: hidden;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    @include onlyMobile {
      height: 79px;
    }
    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
      @include onlyMobile {
        max-height: 79px;
      }
    }
  }
  p {
    font-size: 18px;
    line-height: 120%;
    padding: 0 20px;
    height: 106px;
    @include onlyMobile {
      font-size: 12px;
      padding: 0 8px;
      height: 57px;
    }
  }
}
</style>
