<template>
  <div class="switch" ref="switch">
    <p class="container__text mb16">
      <strong>
        Все торговые точки разделяются на 4 зоны.<br
          v-if="device == 'desktop'"
        />
        Зоны и POSm для их оформления:</strong
      >
    </p>
    <div class="grid">
      <div
        v-for="(zone, i) of zones"
        :key="i"
        class="item"
        :class="{ active: selected === zone.color }"
        @click="$emit('changeZone', zone.color)"
      >
        <button :class="zone.color" type="button" />
        <p>{{ zone.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Switch",
  props: {
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      zones: [
        {
          color: "pink",
          title: "Входная группа",
        },
        {
          color: "blue",
          title: "Торговый зал",
        },
        {
          color: "green",
          title: "Витрины",
        },
        {
          color: "orange",
          title: "Кассовая зона",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.switch {
  max-width: 444px;
  box-shadow: 0px 8px 16px -5px rgba(0, 0, 0, 0.12);
  padding: 16px 16px 24px 16px;
  background: #ffffff;
  border-radius: 2px;
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    justify-content: center;
    @include onlyMobile {
      grid-template-columns: repeat(4, 54px);
      grid-gap: 14px;
    }
  }
  .container__text {
    @include onlyMobile {
      font-size: 14px;
      line-height: 120%;
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      position: relative;
      margin-bottom: 14px;
      &::before {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        border: 3px solid #1f2229;
        left: -6px;
        top: -6px;
        border-radius: 50%;
        opacity: 0.05;
        transition: all 0.3s;
      }
      &:hover:before {
        transform: scale(1.05);
        opacity: 1;
        border-color: #797a7f;
      }
      &:active::before {
        transform: scale(1);
        border-color: #1f2229;
      }
      &.pink {
        background: #eb5893;
      }
      &.blue {
        background: #53aee2;
      }
      &.green {
        background: #c7e553;
      }
      &.orange {
        background: #ec6430;
      }
    }
    p {
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      color: #cecece;
      transition: all 0.3s;
      @include onlyMobile {
        font-size: 11px;
      }
    }
  }
  .item.active {
    p {
      color: var(--mainColor);
    }
    button:before {
      border-color: var(--mainColor);
      opacity: 1;
    }
  }
}
</style>
