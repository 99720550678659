<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <RulesContent>
          <template #top>
            <h2 class="title">Примеры оформления прикассовой зоны</h2>
            <SurveyNumbers :config="config">
              <template #title>
                <h3 class="title">
                  Кассовая зона <span>ОБЯЗАТЕЛЬНО</span> должна быть оформлена
                  как минимум одним из следующих POSM:
                </h3>
              </template>
              <template #info>
                <p class="container__text link-m">
                  <strong>Дополнительно могут быть использованы:</strong>
                </p>
                <ul>
                  <!-- <li>
                    <strong
                      class="dop"
                      @mouseenter="config.imgActive = 1"
                      @mouseleave="config.imgActive = 0"
                      >Постеры.</strong
                    >
                    Оптимальное размещение за продавцом на уровне глаз.
                    Коммуникация на постере — в соответствии с инструкцией по
                    оформлению локального канала региона.
                  </li> -->
                  <li>
                    <strong
                      class="dop"
                      @mouseenter="config.imgActive = 2"
                      @mouseleave="config.imgActive = 0"
                      >Стикеры.</strong
                    >
                    Размещаются на оборудовании в прикассовой зоне для
                    визуализации возможности подключения к Tele2.
                  </li>
                  <li>
                    <strong> Картонный муляж/Тейбл-тент А5. </strong>
                  </li>
                  <li>
                    <strong> Буклеты с актуальными тарифными планами. </strong>
                  </li>
                  <!-- <li>
                    <strong>Бренд-блок</strong>
                  </li> -->
                </ul>
                <p class="container__text link-m">
                  Расширенный список POSm для оформления точки продаж можно
                  посмотреть в Каталоге POSm
                </p>
              </template>
            </SurveyNumbers>
          </template>
          <template #bottom>
            <h2 class="title">Правила оформления прикассовой зоны</h2>
            <p class="container__text--big link-m">
              <strong
                >POSm в кассовой зоне должен помогать продавцу консультировать
                клиента и показывать фокусные тарифные планы.</strong
              ><br />
              В очереди у потенциального клиента есть возможность ознакомиться с
              тарифным планом, поэтому в кассовой зоне размещайте POSm с
              рекламой ключевых тарифов.
            </p>
            <div class="white-content">
              <div class="grid align-center">
                <div class="grey">
                  <BeforeAfter
                    before="before-5.png"
                    after="after-5.png"
                    offset="0.8"
                  />
                </div>
                <div>
                  <p class="container__text">
                    <strong
                      >Оформляя кассовую зону необходимо использовать следующие
                      правила:</strong
                    >
                  </p>
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'eye.svg', w: 25, h: 19 }"
                    text="Размещение выше уровня глаз <strong>запрещено</strong> (выше ~1,6м от пола)"
                  />
                  <AttentionIcon
                    class="m24"
                    :icon="{ name: 'back.svg', w: 20, h: 16 }"
                    text="Материалы должны быть доступны для клиента (на уровне вытянутой руки)"
                  />
                  <AttentionIcon :icon="{ name: 'sheets.svg', w: 23, h: 24 }">
                    <template #text>
                      <p class="container__text">
                        Нельзя допускать
                        <router-link to="/rules?rule=1" class="link link--blue"
                          >«эффект маскарада»</router-link
                        >
                      </p>
                    </template>
                  </AttentionIcon>
                </div>
              </div>
            </div>
          </template>
        </RulesContent>
        <h1 class="title">Зонирование торговых точек</h1>
        <ButtonLink
          :button="{ name: 'Кассовая зона', path: '/zones?zone=orange' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIcon from "../AttentionIcon.vue";
import BeforeAfter from "../BeforeAfter.vue";
import ButtonLink from "../ButtonLink.vue";
import RulesContent from "../RulesContent.vue";
import SurveyNumbers from "../SurveyNumbers.vue";

export default {
  components: {
    RulesContent,
    AttentionIcon,
    ButtonLink,
    SurveyNumbers,
    BeforeAfter,
  },
  name: "CheckoutAreaDesign",
  data() {
    return {
      config: {
        img: ["rule-6.png", "rule-6-posters.png", "rule-6-stickers.png"],
        imgActive: 0,
        buttons: [
          {
            bg: "#53AEE2",
            number: 3,
            left: 21,
            top: 44,
            flag: "Промо-материалы (формат А5-А4) (Есим, Твикс, Микс)",
          },
          { bg: "#EC6430", number: 1, left: 69, top: 44, flag: "Монетница" },
          { bg: "#FF59A3", number: 2, left: 55, top: 45, flag: "Тейблмат" },
        ],
        points: [
          {
            bg: "#EC6430",
            number: 1,
            link: true,
            text: "Монетница",
            desc: "Размещается, где место  расчета",
          },
          {
            bg: "#FF59A3",
            number: 2,
            link: true,
            text: "Тейблмат (А3/A4)",
            desc: "Размещается, где место  расчета",
          },
          {
            bg: "#53AEE2",
            number: 3,
            link: true,
            text: "Промо-материалы (формат А5-А4) (Есим, Твикс, Микс)",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.rule {
  .grey-block {
    padding-bottom: 64px;
    @include tablet {
      padding-bottom: 24px;
    }
  }
  h2.title {
    margin-bottom: 32px;
    @include tablet {
      display: none;
    }
  }
  .white-content {
    padding: 24px;
    background: #ffffff;
    margin-top: 32px;
    img.bs {
      box-shadow: 0px 5px 16px rgba(34, 47, 82, 0.12);
      border-radius: 4px;
    }
    @include tablet {
      margin-top: 24px;
      padding: 0;
    }
  }
  .dop {
    transition: all 0.3s;
    &:hover {
      color: #a1c611;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding-bottom: 24px;
    }
    &.align-center {
      align-items: center;
    }
    .m24 {
      margin: 24px 0;
      @include tablet {
        margin: 16px 0;
      }
    }
  }
  h3.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 16px;
    @include tablet {
      font-size: 18px;
      line-height: 140%;
    }
    span {
      color: #ff59a3;
    }
  }
  .link-m.container__text {
    margin: 32px 0 8px 0;
    @include tablet {
      margin: 24px 0 8px 0;
    }
    a {
      font-size: 16px;
    }
  }
  h1.title {
    font-family: var(--fontStencil);
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 56px;
    margin-bottom: 16px;
    @include tablet {
      margin-top: 24px;
      font-size: 20px;
      line-height: 120%;
    }
  }
  .button-link {
    max-width: 252px;
    @include onlyMobile {
      max-width: 100%;
    }
  }
  .grey {
    @include tablet {
      margin: 0 -15px;
      padding: 18px 15px 18px 15px;
      background: #f0f1f2;
    }
  }
  &-white {
    padding: 18px 20px 14px 16px;
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
</style>
