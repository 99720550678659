<template>
  <Content>
    <template #title> Форматы торговых точек </template>
    <template #main>
      <div class="formats">
        <div class="container">
          <p class="container__text">
            Точки продаж бывают как профильные (FS/LS), т.е. специализируются на
            продаже мобильной техники, так и непрофильные (FA/LA), которые
            специализируются на продаже других категорий товаров, например:
            продуктовый магазин.
          </p>
          <ButtonMulti
            :buttons="['FS/LS', 'FA/LA']"
            :selected="selected"
            @select="
              selected = $event.includes('LS') ? 'LS' : 'LA';
              $router.push({
                query: { format: $event.includes('LS') ? 'LS' : 'LA' },
              });
            "
            class="formats-multi"
          />
        </div>
        <FormatLS v-if="selected.toUpperCase() === 'LS'" />
        <FormatLA v-else />
      </div>
    </template>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import ButtonMulti from "@/components/ButtonMulti.vue";
import FormatLS from "@/components/formats/FormatLS.vue";
import FormatLA from "@/components/formats/FormatLA.vue";

export default {
  components: { Content, ButtonMulti, FormatLS, FormatLA },
  name: "Formats",
  data() {
    return {
      selected: "LS",
    };
  },
  watch: {
    "$route.query": function (val) {
      this.selected = val?.format || "LS";
    },
  },
  created() {
    let queryFormat = this.$route.query.format || "LS";
    if (queryFormat != "LS" && queryFormat != "LA") this.selected = "LS";
    else this.selected = queryFormat;
  },
};
</script>

<style lang="scss" scoped>
.formats {
  &-multi {
    margin: 18px 0 36px 0;
    &::before {
      z-index: -1;
    }
  }
  .grey-block {
    padding: 24px 0 72px 0;
    @include tablet {
      padding: 16px 0 24px 0;
    }
  }
}
</style>
