<template>
  <div class="formats-ls grey-block">
    <div class="container">
      <p class="container__text">
        Мультибрендовые торговые точки FS/LS – это точки продаж с основной
        специализацией по предоставлению услуг мобильной связи (подключение к
        операторам), продаже мобильных устройств и смежных услуг.
      </p>
      <div class="grid desktop">
        <div class="item">
          <WhiteBlock img="formats-1.png" />
          <h3 class="item__title">Салон</h3>
          <ul>
            <li><strong>Площадь:</strong><br />от 10 до 200 м².</li>
            <li>
              <strong>Характеристики:</strong><br />представляет собой салон по
              продаже продуктов сотовых операторов, оборудования, аксессуаров,
              услуг сотовой связи.
            </li>
            <li>
              <strong>Тип обслуживания:</strong><br />производится со всех
              сторон.
            </li>
          </ul>
        </div>
        <div class="item">
          <WhiteBlock img="formats-2.png" />
          <h3 class="item__title">Модуль</h3>
          <ul>
            <li>
              <strong>Площадь:</strong><br />от 2 до 6 м² (без отдельного
              входа).
            </li>
            <li>
              <strong>Характеристики:</strong><br />представляет собой
              стойку-ресепшен с витринами, чаще всего располагается внутри ТЦ
              или другого крупного крытого торгового помещения.
            </li>
            <li>
              <strong>Тип обслуживания:</strong><br />производится со всех
              сторон.
            </li>
          </ul>
        </div>
        <div class="item">
          <WhiteBlock img="formats-3.png" />
          <h3 class="item__title">Стойка</h3>
          <ul>
            <li>
              <strong>Площадь:</strong><br />не требуется отдельная площадь
            </li>
            <li>
              <strong>Характеристики:</strong><br />представляет собой
              сборно-разборную мобильную конструкцию. Устанавливается временно
              или сезонно в местах массового скопления людей.
            </li>
            <li>
              <strong>Тип обслуживания:</strong><br />за стойкой конструкции.
            </li>
          </ul>
        </div>
      </div>
      <div class="grid tablet">
        <Swiper
          :pagination="{
            el: '.swiper-pagination',
          }"
          :autoHeight="true"
          :spaceBetween="23"
          :slidesPerView="1"
        >
          <SwiperSlide>
            <div class="item">
              <WhiteBlock img="formats-1.png" />
              <h3 class="item__title">Салон</h3>
              <ul>
                <li><strong>Площадь:</strong><br />от 10 до 200 м².</li>
                <li>
                  <strong>Характеристики:</strong><br />представляет собой салон
                  по продаже продуктов сотовых операторов, оборудования,
                  аксессуаров, услуг сотовой связи.
                </li>
                <li>
                  <strong>Тип обслуживания:</strong><br />производится со всех
                  сторон.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="item">
              <WhiteBlock img="formats-2.png" />
              <h3 class="item__title">Модуль</h3>
              <ul>
                <li>
                  <strong>Площадь:</strong><br />от 2 до 6 м² (без отдельного
                  входа).
                </li>
                <li>
                  <strong>Характеристики:</strong><br />представляет собой
                  стойку-ресепшен с витринами, чаще всего располагается внутри
                  ТЦ или другого крупного крытого торгового помещения.
                </li>
                <li>
                  <strong>Тип обслуживания:</strong><br />производится со всех
                  сторон.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="item">
              <WhiteBlock img="formats-3.png" />
              <h3 class="item__title">Стойка</h3>
              <ul>
                <li>
                  <strong>Площадь:</strong><br />не требуется отдельная площадь
                </li>
                <li>
                  <strong>Характеристики:</strong><br />представляет собой
                  сборно-разборную мобильную конструкцию. Устанавливается
                  временно или сезонно в местах массового скопления людей.
                </li>
                <li>
                  <strong>Тип обслуживания:</strong><br />за стойкой
                  конструкции.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <div class="swiper-pagination" />
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import WhiteBlock from "../WhiteBlock.vue";
SwiperCore.use([Pagination]);

export default {
  components: { WhiteBlock, Swiper, SwiperSlide },
  name: "FormatsLS",
};
</script>

<style lang="scss" scoped>
.formats-ls {
  .container__text {
    max-width: 731px;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 348px);
    grid-gap: 36px;
    margin-top: 24px;
    @include tablet {
      grid-template-columns: 1fr;
    }
  }
  .item {
    width: 348px;
    @include tablet {
      margin: 0 auto;
    }
    @include onlyMobile {
      width: 290px;
    }
    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 140%;
      margin: 24px 0 8px 0;
      @include onlyMobile {
        margin: 16px 0 8px 0;
      }
    }
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: grid;
    }
  }
  .swiper-container {
    margin: 0 -15px;
    padding: 0 15px 26px 15px;
    .swiper-pagination {
      bottom: -16px !important;
    }
  }
}
</style>
