<template>
  <div
    class="absolute"
    :style="{ left: button.left + '%', top: button.top + '%' }"
  >
    <div class="button-number">
      <div
        class="context"
        :class="[
          button.direction || 'to-right',
          { active: isShowContext || button.active },
        ]"
      >
        <span>{{ button.flag }}</span>
      </div>
      <button
        :style="{
          background: button.bg,
          borderColor: button.active ? button.bg : borderColor,
        }"
        class=""
        type="button"
        @mouseenter="
          $emit('enter', button.number),
            (borderColor = button.bg),
            (isShowContext = true)
        "
        @mouseleave="
          $emit('enter', 0), (borderColor = '#000000'), (isShowContext = false)
        "
      >
        {{ button.number }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonNumber",
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      borderColor: "#000000",
      isShowContext: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.absolute {
  position: absolute;
}
.button-number {
  position: relative;
  button {
    // position: absolute;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
    color: #ffffff;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #000000;
    transition: all 0.3s;
    padding: 5px 0;
    font-weight: bold;
    cursor: auto;
  }

  .context {
    position: absolute;
    font-size: 16px;
    line-height: 140%;
    padding: 4px 8px;
    border-radius: 4px;
    bottom: calc(100% + 5px);
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: all 0.3s;
    white-space: nowrap;
    pointer-events: none;
    z-index: 10;
    @include onlyMobile {
      max-width: 250px;
      span {
        white-space: normal;
      }
    }
    &.to-right {
      left: 0;
    }
    &.to-left {
      right: 0;
    }
    &.active {
      opacity: 1;
    }
  }
}
</style>
