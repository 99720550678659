<template>
  <div :class="[{ active: isOpen }]" class="slct" @click="toggleDrop">
    <div :class="['slct__select--default', 'slct__select']">
      <p class="slct__holder">
        {{ holder }}
      </p>
      <p v-if="value" class="slct__value">
        {{ value.name }}
      </p>

      <div class="slct__wrap">
        <div ref="slctBox" class="slct__box">
          <div class="slct__list">
            <input
              v-model="searchStr"
              type="text"
              class="slct__search"
              placeholder="Поиск по списку"
            />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.5 13.5C11.2614 13.5 13.5 11.2614 13.5 8.5C13.5 5.73858 11.2614 3.5 8.5 3.5C5.73858 3.5 3.5 5.73858 3.5 8.5C3.5 11.2614 5.73858 13.5 8.5 13.5ZM13.596 12.5353C14.475 11.4268 15 10.0247 15 8.5C15 4.91015 12.0899 2 8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15C10.0247 15 11.4268 14.475 12.5353 13.596L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L13.596 12.5353ZM5.89723 9.21978C6.25188 9.21978 6.5 8.85466 6.5 8.5C6.5 7.78555 6.87462 7.15859 7.43815 6.80485C7.74427 6.61269 8.00001 6.31038 8.00001 5.94894C8.00001 5.46683 7.56598 5.0915 7.12283 5.28135C5.87452 5.81617 5 7.05596 5 8.5C5 8.55588 5.00131 8.61146 5.0039 8.6667C5.01913 8.99151 5.30703 9.21978 5.6322 9.21978H5.89723Z"
                fill="#798795"
              />
            </svg>

            <div class="overflow">
              <div
                v-for="(item, i) in search"
                :key="i"
                :class="{ active: value === item }"
                class="slct__item"
                @click.stop="changeSelect(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="slct__arrow">
        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 1L8 7.5L14.5 1"
            stroke="#A3ADB8"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Object],
      required: true,
    },
    holder: {
      type: String,
      default: "",
    },
    array: {
      type: [Object, Array],
      default: () => ({}),
    },
    classEl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      searchStr: "",
    };
  },
  computed: {
    search() {
      if (!this.searchStr) {
        return this.array;
      }
      const regexp = new RegExp(this.searchStr, "gi");
      return this.array.filter((el) => regexp.test(el.name));
    },
  },

  methods: {
    changeSelect(item) {
      this.$emit("changeSelect", item);
      this.close();
    },

    closeClick(e) {
      if (!e) return;
      if (!this.$el.contains(e.target)) {
        this.close();
      }
    },

    close() {
      this.isOpen = false;
      const child = this.$refs.slctBox;
      if (child) child.style.height = ``;
      return false;
    },

    toggleDrop(e) {
      if (!e.target.classList.contains("slct__search")) {
        this.isOpen = !this.isOpen;
        const el = e.target.classList.contains("slct")
          ? e.target
          : this.findParent(e.target, "slct");
        const box = el.children[0].children;

        let child = "";
        Array.from(box).forEach((item) => {
          if (item.classList.contains("slct__wrap")) {
            child = item.children[0];
          }
        });
        if (this.isOpen) {
          document.addEventListener("click", (e) => this.closeClick(e));
          const scrollHeight = child.scrollHeight;
          child.style.height = `${scrollHeight}px`;
        } else {
          document.removeEventListener("click", this.closeClick);
          child.style.height = ``;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slct {
  position: relative;
  width: 100%;
  text-align: left;
  &__value {
    font-size: 16px;
    line-height: 22px;
    color: #1f2229;
    margin: 0;
  }
  &__holder {
    margin: 0 0 2px 0;
    font-size: 13px;
    line-height: 18px;
    color: #798795;
  }

  &__select {
    position: relative;
    width: 100%;
    border: none;
    padding: 0;
    background: transparent;

    &--default {
      height: 44px;
      padding: 9px 18px;
      border-radius: 8px;
      background: #f9fafa;
      border: 1px solid #d4d9de;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__wrap {
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    z-index: 10;
  }

  &__search {
    border: 1px solid #d4d9de;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    margin: 0 12px 8px 12px;
    padding: 9px 12px 9px 40px;
    width: calc(100% - 24px);
    position: relative;
    &::placeholder {
      color: #798795;
    }
  }

  &__box {
    width: 100%;
    box-shadow: 0px 50px 30px -40px rgba(31, 34, 41, 0.02),
      0px 2px 8px rgba(31, 34, 41, 0.04), 0px 17px 40px rgba(31, 34, 41, 0.07);
    position: relative;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    height: 0px;
    transition: height 0.3s;
    // padding-top: 12px;
    .icon {
      position: absolute;
      top: 22px;
      left: 24px;
    }
  }

  &__list {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    .overflow {
      overflow-y: auto;
      max-height: 200px;
    }
  }

  &__item {
    padding: 0 20px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    color: #1f2229;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background: #ebedef;
    }
    &.active {
      background: #1f2229;
      color: #ffffff;
    }
  }

  &__arrow {
    position: absolute;
    right: 22px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    transition: all 0.3s;
  }

  &.active {
    .slct__wrap {
      &:before {
        opacity: 1;
      }
    }
    &__arrow {
      transform: rotate(-180deg);
    }
  }
}
</style>
