<template>
  <div class="rule">
    <div class="grey-block">
      <div class="container">
        <div class="rule-wrapper">
          <h2>Обязательные POSM</h2>
          <table class="table" align="center" cellpadding="0" cellspacing="0">
            <tbody>
              <tr>
                <td class="title border-top-left">POSM</td>
                <td class="title">Группа POSM</td>
                <td class="title">Target</td>
                <td class="title border-top-right">Вес</td>
              </tr>
              <tr>
                <td class="white">Бренд-блок</td>
                <td class="white" rowspan="3">Тарифные материалы</td>
                <td class="white" rowspan="3">85%</td>
                <td class="white last" rowspan="3">15%</td>
              </tr>
              <tr>
                <td class="white">Тейбл-тент</td>
              </tr>
              <tr>
                <td class="white">Муляж картонный А5</td>
              </tr>
              <tr>
                <td class="grey">Монетница</td>
                <td class="grey" rowspan="5">Касса</td>
                <td class="grey" rowspan="5">85%</td>
                <td class="grey last" rowspan="5">25%</td>
              </tr>
              <tr>
                <td class="grey">Тейбл-мат</td>
              </tr>
              <tr>
                <td class="grey">
                  Промо-материалы (формат А5-А4) (Есим, Твикс, Микс)
                </td>
              </tr>
              <tr>
                <td class="grey">Гуталин (внутр оформл.)</td>
              </tr>
              <tr>
                <td class="grey">Паттерн (внутр. оформление)</td>
              </tr>
              <tr>
                <td class="white">Плакаты в рамках (А1-А4)/ Плакаты (А1-А4)</td>
                <td class="white">Плакаты</td>
                <td class="white">60%</td>
                <td class="white last">25%</td>
              </tr>
              <tr>
                <td class="grey">Стикер бабл</td>
                <td class="grey" rowspan="5">Стикеры и фасадная табличка</td>
                <td class="grey" rowspan="5">85%</td>
                <td class="grey last" rowspan="5">15%</td>
              </tr>
              <tr>
                <td class="grey">
                  Стикер<br v-if="device === 'mobile'" />
                  для обозначения ТТ.
                </td>
              </tr>
              <tr>
                <td class="grey">Стикер навигационный</td>
              </tr>
              <tr>
                <td class="grey">Фасадная табличка</td>
              </tr>
              <tr>
                <td class="grey">
                  Акционные продуктовые стикеры (Есим, Твикс, Микс)
                </td>
              </tr>
              <tr>
                <td class="white">Потолочный мобайл</td>
                <td class="white" rowspan="3">
                  Флаг/Потолочный мобайл/Стоппер
                </td>
                <td class="white" rowspan="3">60%</td>
                <td class="white last" rowspan="3">10%</td>
              </tr>
              <tr>
                <td class="white">Стоппер</td>
              </tr>
              <tr>
                <td class="white">Флаг</td>
              </tr>
              <tr>
                <td class="grey border-bottom-left">
                  Внешнее оформление: гуталин, фасадный баннер, паттерн
                </td>
                <td class="grey">Гуталин/Фасадный баннер/Паттерн</td>
                <td class="grey">20%</td>
                <td class="grey last border-bottom-right">10%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequiredPOSM",
};
</script>

<style lang="scss" scoped>
.rule {
  position: relative;
  .grey-block {
    @include tablet {
      background: white;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    background: white;
    height: 192px;
    z-index: 0;
    @include tablet {
      display: none;
    }
  }
  &-wrapper {
    padding-top: 40px;
    padding-bottom: 64px;
    position: relative;
    z-index: 1;
    @include onlyMobile {
      padding-top: 24px;
      padding-bottom: 36px;
    }
  }
  h2 {
    font-family: var(--fontSerif);
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin: 0 0 40px 0;
    @include onlyMobile {
      font-size: 22px;
      line-height: 120%;
      margin: 0 0 24px 0;
    }
  }
  .table {
    width: 100%;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #1f2229;
    filter: drop-shadow(0px 5px 16px rgba(34, 47, 82, 0.12));
    .border-top-left {
      border-top-left-radius: 4px;
    }
    .border-top-right {
      border-top-right-radius: 4px;
    }
    .border-bottom-left {
      border-bottom-left-radius: 4px;
    }
    .border-bottom-right {
      border-bottom-right-radius: 4px;
    }
    td:first-child {
      width: 38%;
      @include onlyMobile {
        width: 42%;
      }
    }
    td:nth-child(2) {
      width: 38%;
      @include onlyMobile {
        width: 32%;
      }
    }
    td:nth-child(3) {
      width: 12%;
      @include onlyMobile {
        width: 13%;
      }
    }
    td:nth-child(4) {
      width: 12%;
      @include onlyMobile {
        width: 13%;
      }
    }
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
    td {
      font-size: 16px;
      line-height: 120%;
      padding: 8px 2px;
      @include onlyMobile {
        font-size: 10px;
        padding: 6px 0px;
      }
    }
    .title {
      background: #1f2229;
      color: white;
      font-weight: 700;
      border-right: 1px solid #ffffff;
      &:last-child {
        border-right: none;
      }
    }
    .white,
    .grey {
      font-weight: 400;
      border-bottom: 1px solid #1f2229;
      &:not(.last) {
        border-right: 1px solid #1f2229;
      }
    }
    .white {
      background: white;
    }
    .grey {
      background: #f0f1f2;
    }
  }
}
</style>
