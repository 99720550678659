<template>
  <Content>
    <template #title> Зонирование торговых точек </template>
    <template #main>
      <div class="zone">
        <SwitchMobile
          v-if="device == 'mobile'"
          @changeZone="
            (zone = $event), $router.push({ query: { zone: $event } })
          "
          :selected="zone"
          :is-showed="isShowedSwitchMobile"
        />
        <div class="container">
          <p class="container__text mb18">
            В зависимости от формата торговой точки некоторые зоны могут
            отсутствовать (в зависимости от планировки)
          </p>
          <div class="list">
            <span>Например:</span>
            <ul class="small">
              <li>
                <router-link to="/formats?format=LA" class="link link--blue"
                  >киоски</router-link
                >
                не имеют торгового зала,
              </li>
              <li>
                <router-link to="/formats?format=LS" class="link link--blue"
                  >стойки</router-link
                >
                не имеют витрин и торгового зала
              </li>
            </ul>
          </div>
        </div>
        <div class="grey-block">
          <StickyComponent>
            <template #static>
              <div class="reverse">
                <Switch
                  ref="switch"
                  :selected="zone"
                  @changeZone="
                    (zone = $event), $router.push({ query: { zone: $event } })
                  "
                />
                <div class="image">
                  <h6 class="title mb16">
                    Схематичный пример зонирования точки продаж:
                  </h6>
                  <img src="@/assets/img/zone-pink.png" v-if="zone == 'pink'" />
                  <img
                    src="@/assets/img/zone-blue.png"
                    v-else-if="zone == 'blue'"
                  />
                  <img
                    src="@/assets/img/zone-green.png"
                    v-else-if="zone == 'green'"
                  />
                  <img
                    src="@/assets/img/zone-orange.png"
                    v-else-if="zone == 'orange'"
                  />
                </div>
              </div>
            </template>
            <template #scroll>
              <ZonePink v-if="zone == 'pink'" />
              <ZoneBlue v-else-if="zone == 'blue'" />
              <ZoneGreen v-else-if="zone == 'green'" />
              <ZoneOrange v-else-if="zone == 'orange'" />
            </template>
          </StickyComponent>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import StickyComponent from "@/components/StickyComponent.vue";
import Switch from "@/components/Switch.vue";
import ZonePink from "@/components/zones/ZonePink.vue";
import ZoneBlue from "@/components/zones/ZoneBlue.vue";
import ZoneGreen from "@/components/zones/ZoneGreen.vue";
import ZoneOrange from "@/components/zones/ZoneOrange.vue";
import SwitchMobile from "@/components/SwitchMobile.vue";

export default {
  components: {
    Content,
    StickyComponent,
    Switch,
    ZonePink,
    ZoneBlue,
    ZoneGreen,
    ZoneOrange,
    SwitchMobile,
  },
  name: "Zones",
  data() {
    return {
      zones: ["pink", "blue", "green", "orange"],
      zone: "pink",
      isShowedSwitchMobile: false,
      switch: null,
    };
  },
  created() {
    let queryZone = this.$route.query.zone || "pink";
    if (!this.zones.find((el) => el == queryZone)) this.zone = "pink";
    else this.zone = queryZone;
  },
  mounted() {
    this.switch = this.$refs.switch.$refs.switch;
    if (this.device == "mobile")
      window.addEventListener("scroll", this.switchPosition, false);
  },
  watch: {
    "$route.query": function (val) {
      this.zone = val?.zone || "pink";
    },
  },
  methods: {
    switchPosition() {
      const position = this.switch.getBoundingClientRect();
      if (position.y + position.height - 45 < 0)
        this.isShowedSwitchMobile = true;
      else this.isShowedSwitchMobile = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.zone {
  .list {
    margin-bottom: 36px;
    @include onlyMobile {
      margin-bottom: 24px;
    }
    span {
      color: #8f9399;
      @include onlyMobile {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .reverse {
    max-width: 444px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 24px;
      max-width: 540px;
      margin: 0 auto;
    }
  }
  .grey-block {
    padding: 40px 0 72px 0;
    @include tablet {
      padding: 16px 0 24px 0;
    }
  }
  .image {
    margin-top: 32px;
    @include tablet {
      margin-bottom: 16px;
    }
    @include onlyMobile {
      margin-top: 0;
    }
  }
  .desktop {
    @include tablet {
      display: none;
    }
  }
  .tablet {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
</style>
