<template>
  <div
    class="number"
    @mouseenter="$emit('is-active', point.number)"
    @mouseleave="$emit('is-active', 0)"
  >
    <div
      class="point"
      :style="
        point.styles
          ? { ...point.styles, background: point.bg }
          : { background: point.bg }
      "
    >
      {{ point.number }}
    </div>
    <div v-if="!point.link" class="pt2">
      <slot name="content" />
    </div>
    <div v-else>
      <button type="button" class="number-button">
        <slot name="content" />
      </button>
      <p v-if="point.desc" v-html="point.desc" class="container_text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PointNumber",
  props: {
    point: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.number {
  display: grid;
  grid-template-columns: 26px 1fr;
  grid-gap: 8px;
  // align-items: center;
  .point {
    width: 26px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid #000000;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    // line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 4px 0;
  }
  .pt2 {
    padding-top: 2px;
  }
  &-button {
    text-align: left;
    // text-decoration: underline;
    transition: all 0.3s;
    margin-top: 5px;
    cursor: auto;
  }
}
</style>
