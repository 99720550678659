<template>
  <div class="orange">
    <div class="white">
      <h2 class="title">Кассовая зона</h2>
      <div class="info">
        <div class="item mb24">
          <h4 class="title mb8">Задача POSm</h4>
          <p class="container__text">
            Использование POSm в консультации абонента для последующего
            рассмотрения продуктов Tele2.
          </p>
        </div>
        <div class="item">
          <h4 class="title mb8">Примеры POSm</h4>
          <ul class="small">
            <li>Буклеты</li>
            <li>Монетницы</li>
            <li>Тейблмат</li>
            <li>Постеры</li>
            <li>Бренд-блок</li>
          </ul>
        </div>
      </div>
      <div class="survey-block info">
        <h5 class="title">Пример размещения POSm:</h5>
        <Survey :config="surveyConfig" />
        <p class="text">
          Кассовая зона — ограниченное пространство, поэтому там размещают
          малогабаритные POSm: стикеры, буклеты, коробки для чеков и монетницы.
        </p>
        <p class="text">
          Реклама в этой зоне должна быть яркой, чтобы привлекать внимание
          покупателя во время очереди на оплату.
        </p>
      </div>
    </div>
    <h3 class="title">ПОДРОБНЫЕ Правила размещения POSM</h3>
    <div class="grid-buttons">
      <ButtonLink
        :button="{ name: 'Оформление прикассовой зоны', path: '/rules?rule=8' }"
      />
    </div>
  </div>
</template>

<script>
import Survey from "../Survey.vue";
import ButtonLink from "@/components/ButtonLink.vue";

export default {
  components: { Survey, ButtonLink },
  name: "ZoneOrange",
  data() {
    return {
      surveyConfig: {
        img: "survey-4.png",
        buttons: [
          {
            color: "orange",
            left: 33,
            top: 16,
            flag: "Постеры",
          },
          {
            color: "orange",
            left: 56,
            top: 35,
            flag: "Тейблмат",
          },
          {
            color: "orange",
            left: 67,
            top: 36,
            flag: "Монетница",
          },
          {
            color: "orange",
            left: 77,
            top: 24,
            flag: "Буклеты",
            direction: "to-left",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.orange {
  .item {
    h4.title {
      background: #ec6430;
    }
  }
}
h2.title {
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 32px;
  @include onlyMobile {
    margin-bottom: 16px;
    font-size: 20px;
  }
}
.info {
  padding: 24px;
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 32px;
  @include tablet {
    box-shadow: 0px 8px 16px -5px rgba(0, 0, 0, 0.12);
  }
  @include onlyMobile {
    padding: 16px 16px 24px 16px;
    margin-bottom: 40px;
  }
  h5.title {
    font-family: var(--fontSerif);
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 34px;
  }
  .item {
    h4.title {
      color: #ffffff;
      font-weight: bold;
      font-size: 12px;
      line-height: 120%;
      padding: 4px 8px;
      border-radius: 2px;
      width: fit-content;
    }
  }
}
.survey-block {
  margin-bottom: 56px;
  @include tablet {
    box-shadow: none;
    padding: 0 0 24px 0;
  }
  @include onlyMobile {
    margin-bottom: 24px;
  }
  .text {
    font-size: 16px;
    line-height: 140%;
  }
  & .text:last-child {
    margin: 0;
  }
  & .text {
    margin: 24px 0;
    @include onlyMobile {
      margin: 16px 0;
    }
  }
  h5.title {
    margin-bottom: 24px;
    @include onlyMobile {
      margin-bottom: 16px;
    }
  }
}
h3.title {
  font-size: 24px;
  line-height: 120%;
  font-family: var(--fontStencil);
  text-transform: uppercase;
  margin-bottom: 16px;
  @include onlyMobile {
    font-size: 20px;
    line-height: 120%;
  }
}
.grid-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 36px;
  @include onlyMobile {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
</style>
