<template>
  <div class="instr-modal">
    <transition name="fade" appear mode="out-in">
      <div class="instr-modal__back" />
    </transition>
    <transition name="fade" appear mode="out-in">
      <div class="instr-modal__box">
        <ButtonClose class="close" @close="$emit('close')" />
        <h2 class="instr-modal__title">
          {{ modal.title }}
        </h2>
        <div v-if="modal.type === 'regional'" class="select">
          <Select
            holder="Ваш регион"
            :value="select"
            :array="regions"
            @changeSelect="(select = $event), fetchDocs()"
          />
        </div>
        <div v-if="files.length" class="files">
          <div v-for="(file, i) of files" :key="i" class="file">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.94484 8.34385H14.5841C14.9919 8.33036 15.318 7.99675 15.318 7.58668C15.318 7.17661 14.9919 6.84226 14.5849 6.82951H4.94409C4.53552 6.84301 4.21016 7.17661 4.21016 7.58668C4.21016 7.99675 4.53627 8.33111 4.94334 8.34385H4.94484ZM14.5849 12.3366C15.0032 12.3366 15.342 11.9978 15.342 11.5794C15.342 11.1611 15.0032 10.8223 14.5849 10.8223H4.94559C4.52727 10.8223 4.18842 11.1611 4.18842 11.5794C4.18842 11.9978 4.52727 12.3366 4.94559 12.3366H14.5849ZM15.998 0.00149934H3.53245C3.51671 0.00149934 3.49797 0.000749672 3.47923 0.000749672C1.57956 0.000749672 0.0352346 1.52333 0 3.41551V20.5815C0.0344849 22.4767 1.57956 24 3.47998 24C3.49872 24 3.51671 24 3.53545 23.9993H12.6387C12.6395 23.9993 12.6402 23.9993 12.6417 23.9993C13.234 23.9993 13.7692 23.7571 14.1546 23.3665L18.9052 18.5994C19.2913 18.2073 19.5305 17.669 19.5312 17.0745V3.41701C19.496 1.52258 17.9516 0 16.052 0C16.0332 0 16.0145 0 15.9958 0.000749672L15.998 0.00149934ZM1.4806 20.5822V3.41925C1.52408 2.35847 2.39445 1.51509 3.46199 1.51509C3.48598 1.51509 3.51071 1.51584 3.5347 1.51659H15.9973C16.0175 1.51584 16.0422 1.51509 16.0662 1.51509C17.1338 1.51509 18.0041 2.35847 18.0476 3.41551V16.2514H12.9446C12.5345 16.2566 12.2039 16.591 12.2039 17.0018C12.2039 17.0048 12.2039 17.0071 12.2039 17.0101V22.4879H3.53096C3.51071 22.4887 3.48598 22.4894 3.46199 22.4894C2.3937 22.4894 1.52333 21.6453 1.4806 20.5875V20.5822ZM13.6883 21.712V17.765H17.6218L13.6883 21.712Z"
                fill="#1F2229"
              />
            </svg>

            <a :href="mediaUrl + '/' + file.pdf" target="_blank">{{
              fileName(file)
            }}</a>
            <span />
            <span>{{ size(file.document_size) }}</span>
          </div>
        </div>
        <div
          v-else-if="
            (!files.length && select) ||
            (!files.length && modal.type !== 'regional')
          "
          class="files"
        >
          <span class="not-found">Файлы не найдены.</span>
        </div>
        <button type="button" class="close-2" @click="$emit('close')">
          Закрыть
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonClose from "./ButtonClose.vue";
import { getRegions, getDocs } from "@/api";
import Select from "@/components/Select.vue";

export default {
  name: "InstructionsModal",
  components: {
    ButtonClose,
    Select,
  },
  props: {
    modal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      select: "",
      regions: [],
      fetchRegions: getRegions,
      documents: getDocs,
      files: [],
    };
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_SITE_MEDIA;
    },
  },
  async created() {
    if (this.modal.type_id === 1) {
      this.files = await this.documents({
        section_id: this.modal.section_id,
      });
    } else {
      this.regions = await this.fetchRegions();
    }
  },
  mounted() {
    document.addEventListener("keyup", (e) => this.close(e));
    document.body.style.overflow = "hidden";
  },
  unmounted() {
    document.removeEventListener("keyup", this.close);
  },
  beforeUnmount() {
    document.body.style.overflow = "auto";
  },
  methods: {
    close(e) {
      if (e.keyCode === 27) {
        this.$emit("close");
      }
    },
    async fetchDocs() {
      this.files = await this.documents({
        region_ids: this.select.id,
        section_id: this.modal.section_id,
      });
    },
    fileName(item) {
      const arr = item.pdf.split(".");
      return item.name + "." + arr[arr.length - 1];
    },
    size(num) {
      return Math.round(num / 1024) < 1024
        ? `${Math.round(num / 1024)} Кб.`
        : `${Math.round(num / 1024 / 1024)} Мб.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.instr-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__back {
    background: #000000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include onlyMobile {
      background: none;
    }
  }
  &__box {
    background: white;
    padding: 30px 40px;
    max-width: 732px;
    width: 100%;
    z-index: 1;
    border: 2px solid #1f2229;
    position: relative;
    @include tablet {
      max-width: 600px;
    }
    @include onlyMobile {
      max-width: none;
      width: 100%;
      border: none;
      padding: 16px 18px;
      height: calc(100vh - 32px);
      overflow-y: auto;
    }
    .desktop {
      @include onlyMobile {
        display: none;
      }
    }
    .mobile {
      display: none;
      @include onlyMobile {
        display: inline-block;
      }
    }
    .close {
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: 34px;
      right: 44px;
      @include onlyMobile {
        top: 20px;
        right: 22px;
      }
      &-2 {
        border: none;
        background: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        text-decoration-line: underline;
        padding: 0;
        @include onlyMobile {
          margin: 0 auto;
          display: block;
        }
      }
    }
    .select {
      margin-bottom: 27px;
      max-width: 500px;
      @include onlyMobile {
        padding-right: 36px;
      }
    }
  }
  &__title {
    font-family: "Tele2 DisplaySerif";
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 18px 0;
    max-width: 550px;
    @include onlyMobile {
      font-size: 24px;
      line-height: 28px;
      max-width: calc(100% - 40px);
    }
  }

  .files {
    .not-found {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
      display: inline-block;
    }
  }

  .file {
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 14px;
    row-gap: 4px;
    margin-bottom: 18px;
    a {
      font-size: 16px;
      line-height: 22px;
      text-decoration-line: underline;
      width: fit-content;
    }
    span {
      font-size: 12px;
      line-height: 22px;
      color: #8f9399;
    }
  }
}
</style>
